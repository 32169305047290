import React, { useContext } from "react"
import styled, { ThemeContext } from "styled-components"
import dayjs from "dayjs";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import '../assets/style/DateTimePicker.scss'

interface InputInterface {
	value: string
	label?: string
	errorLabel?: string
	infoLabel?: string
	placeholder?: string
	onChange: Function
	onValueChange?: Function
	type?: string
	min?: number
	max?: number
	defaultDate?: number
	child?: any
	required?: boolean
}


export default function InputField({value, label, type, errorLabel, infoLabel, child, placeholder, required, defaultDate = 0, min, max, onChange, onValueChange, ref, ...props}: InputInterface | any) {
	// @ts-ignore
	const theme = useContext(ThemeContext)
	
	const d = new Date();
	d.setDate(d.getDate() + defaultDate);
	const defaultValue = d.valueOf();
	const [selectedDay, setSelectedDay] = React.useState(defaultValue);

	
	React.useEffect(() => {
		onValueChange && onValueChange(d.getTime() + 300000)
	}, [])

	return <div style={{margin: '1.5rem 0'}}>
				<fieldset className="InputField" style={{border: `1px solid ${theme.strokeWhite}`, position: 'relative',  padding: (type === "date" ? '0 0.5rem' : '0.5rem 1.2rem 1rem')}} {...props}>
				{
					label && <legend>{label} {required && <span style={{color: value === '' ? 'red': theme.strokeWhite, fontSize: '1.4rem', margin: 0, padding: 0, lineHeight: 0}}>&nbsp;*</span>}</legend>
				}
				{type && type== "textarea" ? 
					<textarea style={{color: theme.text}} onChange={onChange} placeholder={placeholder || ""} rows={5} value={value}></textarea> :
					type !== "date" ?
						<input type={type || "text"} min={min || 0} max={max} style={{color: theme.text}} onChange={onChange} placeholder={placeholder || ""} value={value} onWheel={(e) => { e.currentTarget.blur()}}/>
						: 
						<>
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<DemoContainer components={['DateTimePicker']}>
									<DateTimePicker 
										defaultValue = {dayjs(selectedDay)}
										onChange={(date) => {
											const time = new Date(date['$y'] + "-" +(date['$M'] + 1) + "-"+ date['$D'] + " " + date['$H'] + ":" + date['$m']).valueOf();
											onValueChange(time)
										}}
									/>
								</DemoContainer>
							</LocalizationProvider>
						</>
				}
				{child}
				</fieldset>
				{
					errorLabel && errorLabel != "" && <p style={{color: '#ff0000', fontSize: '0.8rem', margin: '4px 8px'}}>{errorLabel}</p>
				}
				{
					infoLabel && infoLabel != "" && <p style={{color: theme.text, fontSize: '0.8rem', margin: '4px 8px'}}>{infoLabel}</p>
				}
			</div>
}
