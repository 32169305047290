
import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { ApolloProvider, ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import 'react-toastify/dist/ReactToastify.css';
import 'react-loading-skeleton/dist/skeleton.css';
import './assets/style/index.scss';
import Loading from './components/Loading';
import useStore from './useStore';
import config from './config/config.json';

import Login from './pages/Auth/Login';
import AdminList from './pages/Admin/List';
import AdminAdd from './pages/Admin/Add';
import AdminEdit from './pages/Admin/Edit';
import UserList from './pages/User/List';
import UserDetail from './pages/User/Detail';
import CollectionList from './pages/Collection/List';
import CollectionDetail from './pages/Collection/Detail';
import NftList from './pages/Nft/List';
import Profile from './pages/Profile/Index';
import TokenList from './pages/Token/List';
import Dashboard from './pages/Dashboard/Index';
import WalletDashboard from './pages/Wallet/Index';
import { Now } from './util';

const httpLink = createHttpLink({
	uri: config.REACT_APP_GRAPQLENDPOINT,
});

const client = new ApolloClient({
	link: httpLink,
	cache: new InMemoryCache(),
});

function App() {
	// @ts-ignore
	const { loading, logined, lasttime, update } = useStore()
	React.useEffect(() => {
		update({ loading: false })
		if (logined) {
			if (lasttime + 43200 < Now()) { //session expiration 12 hours, logout
				update({ currentAccountMail: "", currentAccountName: "", currentAccountAvatar: "", currentAccountAddress: "", token: "", logined: false, isMetamask: false, lasttime: 0 });
			}
			update({ lasttime: Now() })
		}
	}, [])

	return (
		<ApolloProvider client={client}>
			<BrowserRouter>
				<Switch>
					<Route exact path="/login" component={Login}></Route>
					<Route exact path="/admin/list" component={AdminList}></Route>
					<Route exact path="/admin/add" component={AdminAdd}></Route>
					<Route exact path="/admin/edit/*" component={AdminEdit}></Route>
					<Route exact path="/user/list" component={UserList}></Route>
					<Route path="/user/detail/*" component={UserDetail}></Route>
					<Route exact path="/collection/list" component={CollectionList}></Route>
					<Route path="/collection/detail/*" component={CollectionDetail}></Route>
					<Route exact path="/nft/list" component={NftList}></Route>
					<Route exact path="/tokens" component={TokenList}></Route>
					<Route exact path="/profile" component={Profile}></Route>
					<Route exact path="/" component={Dashboard}></Route>
					<Route exact path="/dashboard" component={Dashboard}></Route>
					<Route exact path="/wallet" component={WalletDashboard}></Route>
					<Route exact path="*" component={Dashboard}></Route>
				</Switch>
				<ToastContainer autoClose={10000} />
				<Loading width={150} height={150} color={"white"} opacity={0.5} show={!!loading} />
			</BrowserRouter>
		</ApolloProvider>
	)
}

export default App
