import React from 'react'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import styled from 'styled-components'
import { useQuery } from "@apollo/client";
import { GET_PAYLABLE_TOKEN } from '../../graphql/index';
import AdminLayout from '../../components/Layout/Layout'
import { ButtonPrimary } from '../../components/components'
import Modal from '../../components/Modal';
import InputField from '../../components/InputField';
import useStore from '../../useStore'
import { Icon } from '../../components/Icon';
import { getERC20Token } from '../../blockchain/contract';
import { proxy, tips, toChecksumAddress } from '../../util';

interface TokenListInterface {
	paylableTokens: PaylableTokens[]
	showAddModal: boolean
	address: string
	errorAddress: string
	symbol: string
	name: string
	icon: string
	coingeckoid: string
	decimals: number
	editImg: null | any
	imageBlob: Blob | null
	errorSymbol: string
	errorCoingecko: string
}

const TokenList = () => {
	// @ts-ignore

	const { darkMode, logined, token, call, update } = useStore()
	const history = useHistory();

	const refFile = React.useRef<HTMLInputElement>(null)
	const [status, setStatus] = React.useState<TokenListInterface>({
		paylableTokens: [],
		showAddModal: false,
		address: '',
		errorAddress: '',
		symbol: '',
		name: '',
		icon: '',
		decimals: 0,
		coingeckoid: '',
		editImg: null,
		imageBlob: null,
		errorSymbol: '',
		errorCoingecko: ''
	})
	const updateStatus = (params: Partial<TokenListInterface>) => setStatus({ ...status, ...params })

	const { data: paylableTokensInfo, loading: paylableTokensLoading, error: paylableTokensError } = useQuery(GET_PAYLABLE_TOKEN, {
		fetchPolicy: 'network-only'
	})
	React.useEffect(() => {
		if (paylableTokensLoading || paylableTokensError) return;
		const data = paylableTokensInfo?.getPaylableToken as PaylableTokens[];
		if (!data) return;
		updateStatus({ paylableTokens: data })
	}, [paylableTokensInfo, paylableTokensLoading, paylableTokensError])


	React.useEffect(() => {
		if (!logined) history.push("/login")
	}, [logined])

	const checkAddress = async (address: string) => {
		try {
			update({ loading: true })
			if (toChecksumAddress(address)) {
				const contract = getERC20Token(address);
				const symbol = await contract.symbol()
				const decimals = await contract.decimals()
				const name = await contract.name()
				updateStatus({ address: address, symbol: symbol, name: name, decimals: decimals, errorAddress: "" })
			}
			else {
				updateStatus({ address: address, symbol: "", name: "", decimals: 18, errorAddress: "無効な値" })
			}
			update({ loading: false })

		} catch (err) {
			update({ loading: false })
			console.log(err.message)
		}
	}

	const removeToken = async (address) => {
		try {
			update({ loading: true })
			const result = await call("/api/admin/token-remove", {
				address: address
			}, { authorization: token })
			if (result) {
				switch (result['message']) {
					case "success": {
						tips("success", "トークンが正常に削除されました");
						const _newToken = [];
						status.paylableTokens.forEach(element => {
							if (element.address !== address) _newToken.push(element)
						});
						updateStatus({ paylableTokens: _newToken, showAddModal: false })
						break;
					}
				}
			}
			update({ loading: false })
		} catch (err) {
			tips("error", "トークンを削除できませんでした。")
			update({ loading: false })
			console.log(err.message)
		}
	}

	const addToken = async () => {
		try {
			if (!status.imageBlob) return tips("warning", "アイコン画像を選択してください");
			if (!toChecksumAddress(status.address)) {
				return updateStatus({ errorAddress: "入力値が無効です" });
			}
			if (status.symbol == "") return updateStatus({ errorSymbol: "入力値が無効です" });
			if (status.coingeckoid == "") return updateStatus({ errorCoingecko: "入力値が無効です" });

			var formData = new FormData();
			formData.append('icon', status.imageBlob);
			formData.append('name', status.name);
			formData.append('symbol', status.symbol);
			formData.append('coingecko', status.coingeckoid);
			formData.append('decimals', status.decimals.toString());
			formData.append('address', toChecksumAddress(status.address));
			update({ loading: true })
			var response = (await axios.post(proxy + '/api/admin/token-create', formData, {
				headers: {
					'Content-Type': 'multipart/form',
					'Apollo-Require-Preflight': true,
					authorization: token,
				},
			})) as any
			if (response.status === 200) {
				switch (response.data.message) {
					case "exists same token": {
						tips("warning", "同じトークンが存在する");
						break;
					}
					case "success": {
						tips("success", "トークンが正常に追加されました");
						const _newToken = status.paylableTokens;
						_newToken.push({
							address: status.address,
							symbol: status.symbol,
							name: status.name,
							decimals: status.decimals,
							icon: response.data.icon
						});
						updateStatus({ paylableTokens: _newToken, showAddModal: false })
						break;
					}
				}
			}
			update({ loading: false })
		} catch (err) {
			tips("error", "新しいトークンを追加できませんでした。")
			update({ loading: false })
			console.log(err.message)
		}
	}

	const showAddModal = () => {
		updateStatus({ address: "", symbol: "", name: "", decimals: 18, errorAddress: "", showAddModal: true, imageBlob: null, editImg: null })
	}

	const changeFile = async (event) => {
		const file = event.target.files[0]
		updateStatus({ editImg: URL.createObjectURL(file), imageBlob: file })
	}

	return (
		<AdminLayout>
			<StyledContainer className="container" >
				<h1 className='text-center' style={{ fontWeight: 'bolder' }}>Paylable Tokens list</h1>
				<StyledListPanel darkMode={darkMode} >
					<div className="list-row header">
						<div></div>
						<div>シンボル</div>
						<div>アドレス</div>
						<div></div>
					</div>
					<div className="list-content">
						{
							status.paylableTokens.map((token, index) => (
								<div className="list-row" key={index}>
									<div style={{ textAlign: 'center' }}>
										<img src={token.icon} alt="icon" style={{ width: '35px', height: '35px', borderRadius: '50%' }} />
									</div>
									<div>{token.symbol}</div>
									<div>{token.address}</div>
									{
										!token.isNative ?
											<div className='cursor' onClick={() => { removeToken(token.address) }}>
												<Icon icon="Remove" />
											</div> : <div></div>
									}
								</div>
							))
						}
					</div>
				</StyledListPanel>

				<div className="flex center">
					<ButtonPrimary style={{ width: '250px', marginTop: '1rem' }} onClick={showAddModal}>
						追加
					</ButtonPrimary>
				</div>

				<Modal onClose={() => { updateStatus({ showAddModal: false }) }} show={status.showAddModal} >
					<div className='mt2 mb1 p2'>
						<h1 className='title m0 p0 text-center'>支払いトークンの追加</h1>
						<div className="flex center mt3">
							<StyledUploadPanel
								style={{
									backgroundImage: status.editImg
										? `url(${status.editImg})`
										: '',
									backgroundSize: 'cover',
									backgroundPosition: 'center',
									backgroundRepeat: 'no-repeat',
								}}
								onClick={() => {
									refFile.current?.click()
								}}
							>
								<h3 style={{ margin: 0, padding: 0 }}>Upload file</h3>
								<div className="flex center mt2">
									<Icon icon="DragUpload" />
								</div>
								<input
									type="file"
									accept="image/*"
									hidden
									ref={refFile}
									onChange={(e) => {
										changeFile(e)
									}}
								/>
							</StyledUploadPanel>
						</div>
						<InputField label="アドレス" type="text" errorLabel={status.errorAddress} value={status.address} onChange={(e) => { checkAddress(e.target.value) }}  ></InputField>
						<div className="row">
							<div className="col-lg-6 col-sm-12">
								<InputField label="シンボル" type="text" readonly value={status.symbol} errorLabel={status.errorSymbol}></InputField>
							</div>
							<div className="col-lg-6 col-sm-12">
								<InputField label="Coingecko Id" type="text" errorLabel={status.errorCoingecko} value={status.coingeckoid} onChange={(e) => { updateStatus({ coingeckoid: e.target.value }) }}></InputField>
							</div>
						</div>

						<div className="flex center">
							<ButtonPrimary style={{ width: '250px', marginTop: '2rem' }} onClick={() => { addToken() }}>
								追加する
							</ButtonPrimary>
						</div>
						<div className="flex center">
							<ButtonPrimary style={{ width: '250px', marginTop: '1rem', backgroundColor: "#24262f" }} onClick={() => { updateStatus({ showAddModal: false }) }}>
								キヤンセル
							</ButtonPrimary>
						</div>
					</div>
				</Modal>
			</StyledContainer>
		</AdminLayout>
	)
}

export default TokenList;

const StyledUploadPanel = styled.div`
	background-color:  ${({ theme }) => theme.boxColor};
	padding: 2rem 2rem 3rem;
	border-radius: 1rem;
	cursor: pointer;
	&:hover {
		background-color:  ${({ theme }) => theme.bgColor2};
	}
	h3 {
		margin: 0;
		padding: 0;
	}
`

const StyledContainer = styled.div`
	padding: 2rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	@media (max-width: 1024px) {
		padding: 1rem;
	}
	@media (max-width: 768px) {
		padding: 0;
	}
	min-height: 100vh;
	
	.list-content {
		max-height: 50vh;
		overflow-y: auto;
		min-height: 300px;
	}
	.list-row{
		display: flex;
		flex-direction: row;
		align-items: center;
		align-content: center;
		padding: 8px;
		border-bottom: 1px solid ${({ theme }) => theme.strokeColor};
		@media (max-width: 768px) {
			display: block;
			text-align: center;
		}
		div:nth-child(1) {
			flex: 1;
		}
		div:nth-child(2) {
			flex: 3;
		}
		div:nth-child(3) {
			flex: 7;
		}
		div:nth-child(4) {
			flex: 1;
		}
		&.header {
			font-size: 1.1rem;
			font-weight: bold;
		}
		.nft{
			width: 70px;
			height: 70px;
			border-radius: 8px;
			margin: 8px;
			object-fit: cover;
		}
		.collection {
			width: 50px;
			height: 50px;
			border-radius: 8px;
			margin: 4px 8px;
			object-fit: cover;
		}
	}
`

const StyledListPanel = styled.div<{ darkMode: boolean }>`
	border-radius: 2rem;
	background-color: ${({ theme }) => theme.boxColor};
	padding: 2rem 2rem;
	width: 100%;
	max-width: 840px;
	color: ${({ theme }) => theme.text};
	margin: 1rem auto;
	.title{
		color: ${({ theme, darkMode }) => darkMode ? theme.white : theme.black};
		text-align: center;
		font-size: 2rem;
		margin: 0;
	}
	a{
		text-align: center;
		color: #6b9beb;
	}
	@media (max-width: 768px) {
		width: 100%;
		margin: 1rem auto 1rem;
		a{
			display: block;
		}
		.link{
			text-align: center;
		}
	}
	.logo{
		cursor: pointer;
		width: 240px;
		height: auto;
		@media (max-width: 768px) {
			margin: 0 8px;
			width: 150px;
		}
	}
`
