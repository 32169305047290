import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import styled, { ThemeContext } from 'styled-components'
import { ButtonPrimary } from '../../components/components'
import AdminLayout from '../../components/Layout/Layout'
import useStore from '../../useStore'
import InputField from '../../components/InputField'
import { tips, validatePassword } from '../../util';


interface Admin {
	email: string
	root: boolean
	allow: boolean
	created: number
}

interface AdminEditInterface {
	admin: Admin
	password: string
	password2: string
	name: string
	errorname: string
	errorpassword: string
	errorpassword2: string
	error: string
	root: boolean
}
  

const AdminEdit = () => {
	// @ts-ignore
	
	const {darkMode, token, logined, call, update} = useStore()
	const history = useHistory();	
	const theme = useContext(ThemeContext)

	const [status, setStatus] = React.useState<AdminEditInterface>({
		admin: null,
		password: '',
		password2: '',
		name: window.location.pathname.split("/")?.[3]?.toString() || "",
		errorname: '',
		errorpassword: '',
		errorpassword2: '',
		error: '',
		root: false,
	})
	const updateStatus = (params: Partial<AdminEditInterface>) => setStatus({ ...status, ...params })


	const changeAdmin = async () => {
		try {
			const name = status.name;
			const password = status.password;
			const confirmpassword = status.password2;
			updateStatus({error: "", errorname: "", errorpassword: "",  errorpassword2: ""})
			if(password.trim() === "" || !validatePassword(password))  return updateStatus({ error: "", errorpassword: "上記のパスワードの形式でご入力ください", errorpassword2: "", errorname: ""}); 
			if(confirmpassword.trim() === "" || !validatePassword(confirmpassword))  return updateStatus({error: "", errorpassword: "", errorpassword2: "上記のパスワードの形式でご入力ください", errorname: ""});
			if(password !== confirmpassword) return updateStatus({ error: "", errorpassword: "", errorpassword2: "上記のパスワードの形式でご入力ください", errorname: ""});
			update({loading: true})
			const result = await call("/api/admin/edit-admin", {
				email: name,
				password: password,
				allow: status.root,
				root: status.root,
			}, {authorization: token})
			if(result){
				switch(result['message']) {
					case "success": {
						tips("success", "正常に編集されました"); setTimeout(() => {history.push("/admin/list"); }, 500); break;
					}
					case "Please enter all required data.": updateStatus({errorpassword: "", errorpassword2: "", error: "必要なデータをすべて入力してください。"}); break;
					case "Not exists user": updateStatus({errorpassword: "", errorpassword2: "", error: "ユーザーが存在しません。"}); break;
					case "Not root admin": updateStatus({errorpassword: "", errorpassword2: "", error: "このアクションにはルート権限が必要です"}); break;
					case "Already exists same email": updateStatus({errorpassword: "", errorpassword2: "", error: "同じユーザーが既に存在します"}); break;
					case "internal error": updateStatus({errorpassword: "", errorpassword2: "", error: "Server error"}); break;
				}
			}
			update({loading: false})
		} catch(ex) {
			update({loading: false})
			tips("error", "不明なエラー")
		}
	}

	React.useEffect(() => {
		if(!logined) history.push("/login")
	}, [logined])
	
	return (
		<AdminLayout>
			<StyledContainer className="container">
				<StyledListPanel darkMode={darkMode}>
						<h3 className='title m0 p0'>Edit admin</h3>
						<InputField label="メールアドレス" errorLabel={status.errorname}  value={status.name}placeholder="AAA@gmail.com"></InputField>
						<p>権限</p>
						<div className="flex middle">
							<ButtonPrimary style={{width: '120px', margin: '0 1rem 0 0', backgroundColor: status.root ? theme.buttonPrimary : "#595d65"}} onClick={() => {updateStatus({root: true})}}>Root</ButtonPrimary>
							<ButtonPrimary style={{width: '120px', margin: '0 1rem 0 0',  backgroundColor: !status.root ? theme.buttonPrimary : "#595d65"}} onClick={() => {updateStatus({root: false})}}>Member</ButtonPrimary>
						</div>
						<InputField label="パスワード" type="password" errorLabel={status.errorpassword} value={status.password} onChange={(e) => {updateStatus({password: e.target.value})}}  placeholder=''></InputField>
						<InputField label="パスワード再入力" type="password" errorLabel={status.errorpassword2} value={status.password2} onChange={(e) => {updateStatus({password2: e.target.value})}}  placeholder=''></InputField>
						<div className="flex center mt3 mb3">
							<ButtonPrimary style={{width: '200px'}} onClick = {changeAdmin}>保存</ButtonPrimary>
						</div>
						{
							status.error !== "" && <p style={{textAlign: 'center', color: 'red'}}>{status.error}</p>
						}
				</StyledListPanel>
			</StyledContainer>
		</AdminLayout>
	)
}

export default AdminEdit;



const StyledContainer = styled.div`
	padding: 1rem 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	@media (max-width: 1024px) {
		padding: 1rem 0;
	}
	@media (max-width: 768px) {
		padding: 0;
	}
	min-height: 100vh;
`

const StyledListPanel = styled.div<{darkMode: boolean}>`
	border-radius: 2rem;
	background-color: ${({theme}) => theme.boxColor};
	padding: 2rem 5rem;
	width: 100%;
	max-width: 600px;
	color: ${({theme}) => theme.text};
	margin: 3rem auto;
	.title{
		color: ${({theme, darkMode}) => darkMode ? theme.white:theme.black};
		text-align: center;
		font-size: 2rem;
		margin: 0;
	}
	a{
		text-align: center;
		color: #6b9beb;
	}
	@media (max-width: 768px) {
		width: 100%;
		padding: 2rem 1rem;
		margin: 1rem auto 2rem;
		a{
			display: block;
		}
		.link{
			text-align: center;
		}
	}
`
