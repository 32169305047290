import React from "react";
import styled from "styled-components";
import Icon from "./Icon";

interface SwitchInterface{
	values?: any
	selectedKey?: string
	onChange: Function
	props?: any
	itemProps?: any
	panelProps?: any
	hideImg?: boolean
}

export default function Dropdown({values, selectedKey, hideImg, props, panelProps, itemProps, onChange}: SwitchInterface) {
	// @ts-ignore
	const [status, setStatus] = React.useState({
		selectedKey: selectedKey
	})
	const [showMenu, setShowMenu] = React.useState(false)
	const panelRef = React.useRef(null)

	return (
		<StyledDropdownPanel {...props} onMouseUp={() => { setShowMenu(true) }} onBlur={() => {setShowMenu(false)}}>
			{status.selectedKey}
			<div className="drop-icon">
				<Icon icon="Down" />
			</div>
			<div className="menu-panel" {...panelProps} ref={panelRef} style={{display: showMenu ? 'block' : 'none'}}>
				{
					values && values.map((item, index) => {
						return <div key={index} className="item flex middle" {...itemProps} onBlur={() => {setShowMenu(false) }} onClick={() => {setStatus({selectedKey: item.symbol || item.name}); onChange(item.key || item.symbol); setShowMenu(false)}}>
							{
								!hideImg && (item.img || item.icon) && <img src={item.img || item.icon} style={{width: '25px', height: '25px', marginRight:'10px', borderRadius: '50%'}} />
							}
							{item.symbol || item.name }
						</div>		
					})
				}
			</div>
			{
				showMenu && <div className="background" onClick={() => {setShowMenu(false)}}/>
			}
		</StyledDropdownPanel>
	);
}

const StyledDropdownPanel = styled.div`
	background-color: ${({theme}) => theme.boxColor};
	color: ${({theme}) => theme.text};
	padding: 10px 1rem;
	border-radius: 8px;
	cursor: pointer;
	position: relative;
	min-width: 100px;
	font-size: 0.9rem;
	.background{
		width: 100vw;
		height: 100vh;
		background-color: transparent;
		position: fixed;
		left: 0;
		top: 0;
	}
	.drop-icon{
		position: absolute;
		right: 10px;
		top: 0.5rem;
	}
	.menu-panel{
		position: absolute;
		top: 101%;
		left: 0;
		overflow: hidden;
		width: 100%;
		z-index: 1001;
		max-height: 50vh;
		overflow-y: auto;
		border-radius: 4px;
		.item{
			width: 100%;
			background-color: ${({theme}) => theme.boxColor};
			padding: 1rem;
			transition: 0.2s all;
			color: white;
			&:hover{
				background-color: ${({theme}) => theme.bgColor2}!important;
				color: ${({theme}) => theme.text}!important;
			}
		}
	}
`