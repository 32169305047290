import keccak from 'keccak'
import { ethers } from "ethers";
import { getERC20Token, getTokenContract } from "./contract";

const ZeroAddress = "0x0000000000000000000000000000000000000000"

function stripHexPrefix(value: string) {
	return value.slice(0, 2) === '0x' || value.slice(0, 2) === '0X' ? value.slice(2) : value
}

const toChecksumAddress = (address: string) => {
	try {
		if (typeof address !== 'string') return ''
		if (!/^(0x)?[0-9a-f]{40}$/i.test(address)) throw new Error(`Given address "${address}" is not a valid Ethereum address.`)

		const stripAddress = stripHexPrefix(address).toLowerCase()
		const keccakHash = keccak('keccak256').update(stripAddress).digest('hex');
		let checksumAddress = '0x';
		for (let i = 0; i < stripAddress.length; i++) {
			checksumAddress +=
				parseInt(keccakHash[i], 16) >= 8
					? stripAddress[i]?.toUpperCase()
					: stripAddress[i]
		}
		return checksumAddress
	} catch (err) {
		console.log(err)
		// console.log(err)
		return address
	}
}

const getEthBalance = async (provider: any, address: string) => {
	const balance = await provider.getBalance(toChecksumAddress(address));
	return balance;
}

const getTokenBalance = async (tokenAddress: string, address: any) => {
	try {
		const contract = getERC20Token(tokenAddress)
		const balance = await contract.balanceOf(address)
		return balance;
	} catch (err) {
		return 0;
	}
}

const gasPrice = async (provider: any) => {
	const price_unit = "gwei";
	const price = ethers.utils.formatUnits(await provider.getGasPrice(), price_unit);
	return price;
}

export {
	ZeroAddress,
	toChecksumAddress,
	getEthBalance,
	getTokenBalance,
	gasPrice,
}
