import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { useQuery } from "@apollo/client";
import styled, { ThemeContext } from 'styled-components'
import Modal from '../../components/Modal'
import { Icon } from '../../components/Icon'
import Layout from '../../components/Layout/Layout'
import InputField from '../../components/InputField';
import { ButtonPrimary } from '../../components/components'
import useStore from '../../useStore'
import { ellipsis, tips, validateUrl } from '../../util';
import { GET_CATEGORY, GET_COLLECTION_CACHE_DATA, GET_COLLECTION_INFO } from '../../graphql/index';

import banner from '../../assets/img/banner.webp'
import avatar from '../../assets/img/avatar.png'

interface CollectionDetailInterface {
	showDeleteModal: boolean
	collectionInfo: Collection
	address: string
	items: number
	volume: number
	stopModal: boolean
	restoreModal: boolean
	removeModal: boolean
	addBadgeModal: boolean
	rejectBadgeModal: boolean
	deleteBadgeModal: boolean
	acceptBadgeModal: boolean
	categorylist: string[]
	rejectreason: string
	reasonerror: string
}


const CollectionDetail = () => {
	// @ts-ignore
	const theme = useContext(ThemeContext)
	const { darkMode, logined, token, call } = useStore()
	const history = useHistory();

	const [status, setStatus] = React.useState<CollectionDetailInterface>({
		showDeleteModal: false,
		collectionInfo: null,
		address: window.location.pathname.split("/")?.[3]?.toString() || "",
		items: 0,
		volume: 0,
		stopModal: false,
		restoreModal: false,
		removeModal: false,
		categorylist: [],
		addBadgeModal: false,
		rejectBadgeModal: false,
		deleteBadgeModal: false,
		acceptBadgeModal: false,
		rejectreason: '',
		reasonerror: ''
	})
	const updateStatus = (params: Partial<CollectionDetailInterface>) => setStatus({ ...status, ...params })

	const badgeState = {
		verified: "取得済み",
		rejected: "拒否",
		pending: "申請中",
		"": "未申請"
	}

	//collection
	const { data: collectionInfo, loading: collectionLoading, error: collectionError } = useQuery(GET_COLLECTION_INFO, {
		variables: {
			adminPage: "admin",
			address: status.address,
			badge: (status.collectionInfo?.verified?.status?.toString() || "not") + "," + "not,not,not,not",
		},
		fetchPolicy: 'network-only'
	})

	React.useEffect(() => {
		if (collectionLoading || collectionError) return;
		if (collectionInfo.getCollectionInfo && collectionInfo.getCollectionInfo.length > 0) {
			const result = collectionInfo?.getCollectionInfo[0] as Collection;
			if (!result) return;
			updateStatus({ collectionInfo: result })
		}
	}, [collectionInfo, collectionLoading, collectionError])


	const { data: cacheDataInfo, loading: cacheDataLoading, error: cacheDataError } = useQuery(GET_COLLECTION_CACHE_DATA, {
		variables: {
			address: status.address
		},
		fetchPolicy: 'network-only'
	})

	React.useEffect(() => {
		if (cacheDataLoading || cacheDataError) return;
		const cacheData = cacheDataInfo?.getCollectionCacheData as CollectionData;
		if (!cacheData) return;
		updateStatus({
			items: cacheData.items,
			volume: cacheData.volume
		})
	}, [cacheDataInfo, cacheDataLoading, cacheDataError])

	const { data: categoryInfo, loading: categoryLoading, error: categoryError } = useQuery(GET_CATEGORY, { fetchPolicy: 'network-only' });
	React.useEffect(() => {
		if (categoryLoading || categoryError) return;
		const data = categoryInfo?.getCategory as Category[];
		if (!data) return;
		const v = [];
		data.forEach(element => {
			v.push(element.category)
		});
		updateStatus({
			categorylist: v
		})

	}, [categoryInfo, categoryLoading, categoryError])

	const restoreCollection = async () => {
		try {
			const result = await call("/api/admin/set-collection-allow", {
				collectionid: status.address,
				owner: status.collectionInfo?.owner,
				hide: false
			}, { authorization: token })
			if (result) {
				switch (result['message']) {
					case "success": {
						tips("success", "このコレクションを正常に復元しました");
						updateStatus({ restoreModal: false, collectionInfo: { ...status.collectionInfo, hide: false } })
						break;
					}
					case "internal error": tips("error", "サーバーエラー"); break;
				}
			}
		} catch (ex) {
			return tips("error", "不明なエラー")
		}
	}

	const hideCollection = async () => {
		try {
			const result = await call("/api/admin/set-collection-allow", {
				collectionid: status.address,
				owner: status.collectionInfo?.owner,
				hide: true
			}, { authorization: token })
			if (result) {
				switch (result['message']) {
					case "success": {
						tips("success", "このコレクションは正常に停止されました");
						updateStatus({ stopModal: false, collectionInfo: { ...status.collectionInfo, hide: true } })
						break;
					}
					case "internal error": tips("error", "サーバーエラー"); break;
				}
			}
		} catch (ex) {
			return tips("error", "不明なエラー")
		}
	}

	const removeCollection = async () => {
		try {
			const result = await call("/api/admin/remove-collection", {
				collectionid: status.address,
				owner: status.collectionInfo?.owner,
			}, { authorization: token })

			if (result) {
				switch (result['message']) {
					case "success": {
						tips("success", "このコレクションは正常に削除されました。");
						updateStatus({ removeModal: false });
						history.push("/collection/list");
						break;
					}

					case "internal error": tips("error", "サーバーエラー"); break;
				}
			}
		} catch (ex) {
			return tips("error", "不明なエラー")
		}
	}

	const addBadge = async () => {
		try {
			const result = await call("/api/admin/set-collection-badge", {
				collectionid: status.address,
				owner: status.collectionInfo?.owner,
				status: "verified",
				reason: '',
			}, { authorization: token })
			if (result) {
				switch (result['message']) {
					case "success": {
						tips("success", "バッジを設定しました");
						updateStatus({ addBadgeModal: false, collectionInfo: { ...status.collectionInfo, verified: { status: "verified", reason: '' } } })
						break;
					}
					case "internal error": tips("error", "サーバーエラー"); break;
				}
			}
		} catch (ex) {
			return tips("error", "不明なエラー")
		}
	}

	const deleteBadge = async () => {
		try {
			const result = await call("/api/admin/set-collection-badge", {
				collectionid: status.address,
				owner: status.collectionInfo?.owner,
				status: "",
				reason: '',
			}, { authorization: token })
			if (result) {
				switch (result['message']) {
					case "success": {
						tips("success", "バッジを削除しました");
						updateStatus({ deleteBadgeModal: false, collectionInfo: { ...status.collectionInfo, verified: { status: "", reason: '' } } })
						break;
					}
					case "internal error": tips("error", "サーバーエラー"); break;
				}
			}
		} catch (ex) {
			return tips("error", "不明なエラー")
		}
	}

	const rejectBadge = async () => {
		try {
			if (status.rejectreason.trim() === "") return updateStatus({ reasonerror: "Please input reject reason" })
			const result = await call("/api/admin/set-collection-badge", {
				collectionid: status.address,
				owner: status.collectionInfo?.owner,
				status: "rejected",
				reason: status.rejectreason,
			}, { authorization: token })
			if (result) {
				switch (result['message']) {
					case "success": {
						tips("success", "バッジを拒否しました");
						updateStatus({ rejectBadgeModal: false, collectionInfo: { ...status.collectionInfo, verified: { status: "rejected", reason: status.rejectreason } } })
						break;
					}
					case "internal error": tips("error", "サーバーエラー"); break;
				}
			}
		} catch (ex) {
			return tips("error", "不明なエラー")
		}
	}

	const acceptBadge = async () => {
		try {
			const result = await call("/api/admin/set-collection-badge", {
				collectionid: status.address,
				owner: status.collectionInfo?.owner,
				status: "verified",
				reason: '',
			}, { authorization: token })
			if (result) {
				switch (result['message']) {
					case "success": {
						tips("success", "バッジが正常に承認されました");
						updateStatus({ acceptBadgeModal: false, collectionInfo: { ...status.collectionInfo, verified: { status: "verified", reason: '' } } })
						break;
					}
					case "internal error": tips("error", "サーバーエラー"); break;
				}
			}
		} catch (ex) {
			return tips("error", "不明なエラー")
		}
	}

	React.useEffect(() => {
		if (!logined) history.push("/login")
	}, [logined])

	return (
		<Layout>
			<StyledContainer className="container" >
				<div className="flex justify wrap ml-auto mr-auto w100" style={{ maxWidth: '960px' }}>
					<ButtonPrimary style={{ width: '100px', padding: '4px 8px', margin: '4px', backgroundColor: '#646464' }} onClick={() => { history.push("/collection/list") }}>
						<div style={{ width: '10px', height: '18px', marginRight: '1rem' }}>
							<svg viewBox="189.89 1093 9.149 17.86"><path d="M198.665 1110.486a1.276 1.276 0 0 0 0-1.804l-5.85-5.85a1.303 1.303 0 0 1 0-1.804l5.85-5.85a1.275 1.275 0 1 0-1.804-1.805l-5.85 5.85a3.827 3.827 0 0 0 0 5.412l5.85 5.851a1.276 1.276 0 0 0 1.804 0Z" fill="currentColor" fill-rule="evenodd"></path></svg>
						</div>
						戻る
					</ButtonPrimary>
					<h1 className='text-center ml-auto mr-auto' style={{ fontWeight: 'bolder' }}>Collection detail</h1>
					<div style={{ width: '100px' }} />
				</div>

				<StyledPanel darkMode={darkMode}>
					<img src={status.collectionInfo?.metadata?.coverImage || banner} className='banner' />

					<div className="flex justify middle mt2">
						<div className="flex middle">
							<img src={status.collectionInfo?.metadata?.image || avatar} className='avatar' />

							<div className='ml2'>
								<p className='m0'>{status.collectionInfo?.metadata?.name}</p>
								<p className='p0 m0 cursor' style={{ color: theme.textGrey }} onClick={() => { history.push("/user/detail/" + status.collectionInfo?.owner) }}>by {ellipsis(status.collectionInfo?.owner)}</p>
							</div>
						</div>

						<div className='flex wrap'>
							<p className='ml3'>最終ログイン</p>
							<p className='ml3'>{new Date(status.collectionInfo?.created * 1000).toLocaleDateString()}</p>
						</div>
					</div>

					<div className="flex mt2 wrap">
						{validateUrl(status.collectionInfo?.metadata?.links.find((link) => link.name === "twitter")?.link) && (
							<div className="flex middle ml2 mr2 mt2">
								<Icon icon="Twitter" margin={16} size={24} height={30} />
								<a className='m0 p0' style={{ textDecoration: 'none', color: theme.text }}
									href={status.collectionInfo?.metadata?.links.find((link) => link.name === "twitter")?.link}
								>
									{status.collectionInfo?.metadata?.links.find((link) => link.name === "twitter")?.link}
								</a>
							</div>
						)}

						{validateUrl(status.collectionInfo?.metadata?.links.find((link) => link.name === "instagram")?.link) && (
							<div className="flex middle ml2 mr2 mt2">
								<Icon icon="Instagram" margin={12} size={24} height={30} />
								<a className='m0 p0' style={{ textDecoration: 'none', color: theme.text }}
									href={status.collectionInfo?.metadata?.links.find((link) => link.name === "instagram")?.link}
								>
									{status.collectionInfo?.metadata?.links.find((link) => link.name === "instagram")?.link}
								</a>
							</div>
						)}

						{validateUrl(status.collectionInfo?.metadata?.links.find((link) => link.name === "discord")?.link) && (
							<div className="flex middle ml2 mr2 mt2">
								<Icon icon="Discord" margin={16} size={24} height={30} />

								<a className='m0 p0' style={{ textDecoration: 'none', color: theme.text }}
									href={status.collectionInfo?.metadata?.links.find((link) => link.name === "discord")?.link}
								>
									{status.collectionInfo?.metadata?.links.find((link) => link.name === "discord")?.link}
								</a>
							</div>
						)}

						{validateUrl(status.collectionInfo?.metadata?.links.find((link) => link.name === "website")?.link) && (
							<div className="flex middle ml2 mr2 mt2">
								<Icon icon="Website" margin={4} height={28} size={24} />
								<a className='m0 p0' style={{ textDecoration: 'none', color: theme.text }}
									href={status.collectionInfo?.metadata?.links.find((link) => link.name === "website")?.link}
								>
									{status.collectionInfo?.metadata?.links.find((link) => link.name === "website")?.link}
								</a>
							</div>
						)}
					</div>

					<div className="hr mt3"></div>

					<div className="ml-auto mr-auto" style={{ maxWidth: '600px' }}>
						<div className="flex wrap">
							<p className='mr3'>商品数 {status.items}個</p>
							<p className='mr3'>合計取引額 {status.volume}円</p>
							<p className='mr3'>クリエイター手数料 {status.collectionInfo?.fee || 0}%</p>
						</div>

						<div className="flex wrap">
							{status.collectionInfo?.category && status.collectionInfo?.category.map((element, index) => {
								if (status.categorylist[element]) {
									return <ButtonPrimary style={{ minWidth: '100px', backgroundColor: "#59d398", padding: '4px 8px', margin: '4px' }} key={index}>
										{status.categorylist[element]}
									</ButtonPrimary>
								}
							})
							}
						</div>
					</div>

					<div className="flex center">
						<fieldset className="InputField mt1 mb1" style={{ maxWidth: '600px', width: '100%' }}>
							<legend>bio</legend>
							<p>{status.collectionInfo?.metadata?.description}</p>
						</fieldset>
					</div>

					<div className="flex justify ml-auto mr-auto" style={{ maxWidth: '600px' }}>
						<div className="flex wrap">
							<p className='mr2'>バッジリクエストステータス</p>
							<p className='mr2'>{badgeState[status.collectionInfo?.verified?.status]}</p>
						</div>

						<div className="flex">
							{(status.collectionInfo?.verified?.status === "" || status.collectionInfo?.verified?.status === "rejected") && (
								<ButtonPrimary onClick={() => { updateStatus({ addBadgeModal: true }) }}
									style={{ width: '100px', padding: '4px 8px', margin: '4px', backgroundColor: '#59d398' }}
								>
									バッジ付与
								</ButtonPrimary>
							)}

							{status.collectionInfo?.verified?.status === "pending" && (
								<ButtonPrimary onClick={() => { updateStatus({ acceptBadgeModal: true }) }}
									style={{ width: '100px', padding: '4px 8px', margin: '4px', backgroundColor: '#59d398' }}
								>
									承認
								</ButtonPrimary>
							)}

							{status.collectionInfo?.verified?.status === "pending" && (
								<ButtonPrimary onClick={() => { updateStatus({ rejectBadgeModal: true }) }}
									style={{ width: '100px', padding: '4px 8px', margin: '4px' }}
								>
									拒否
								</ButtonPrimary>
							)}

							{status.collectionInfo?.verified?.status === "verified" && (
								<ButtonPrimary onClick={() => { updateStatus({ deleteBadgeModal: true }) }}
									style={{ width: '100px', padding: '4px 8px', margin: '4px' }}
								>
									バッジ削除
								</ButtonPrimary>
							)}
						</div>
					</div>

					{status.collectionInfo?.verified?.status === "rejected" && (
						<div className="flex center mt1">
							<div style={{ maxWidth: '600px', backgroundColor: '#646464', padding: '1rem', width: '100%' }}>
								<p className='m0'>{status.collectionInfo?.verified?.reason}</p>
							</div>
						</div>
					)}
				</StyledPanel>

				<div className="flex center mt1 mb3" style={{ gap: "10px" }}>
					{status.collectionInfo?.hide && (
						<ButtonPrimary style={{ width: '250px', backgroundColor: "#59d398" }}
							onClick={() => { updateStatus({ restoreModal: true }) }}
						>
							復元する
						</ButtonPrimary>
					)}

					{!status.collectionInfo?.hide && (
						<ButtonPrimary style={{ width: '250px' }}
							onClick={() => { updateStatus({ stopModal: true }) }}
						>
							非表示にする
						</ButtonPrimary>
					)}

					<ButtonPrimary style={{ width: '250px' }}
						onClick={() => { updateStatus({ removeModal: true }) }}
					>
						取り除く
					</ButtonPrimary>
				</div>
			</StyledContainer>

			<Modal onClose={() => { updateStatus({ removeModal: false }) }} show={status.removeModal} >
				<div className='mt2 mb1'>
					<h1 className='title m0 p0 text-center'>コレクションの削除</h1>
					<p className='ml-auto mr-auto text-center' style={{ maxWidth: '500px' }}>
						データベースからコレクションを削除します。
						<br />
						このコレクションは削除後に復元できません。
					</p>

					<div className="flex center">
						<ButtonPrimary onClick={removeCollection}
							style={{ width: '250px', marginTop: '2rem' }}
						>
							取り除く
						</ButtonPrimary>
					</div>

					<div className="flex center">
						<ButtonPrimary style={{ width: '250px', marginTop: '1rem', backgroundColor: "#24262f" }} onClick={() => { updateStatus({ removeModal: false }) }}>キャンセル</ButtonPrimary>
					</div>
				</div>
			</Modal>

			<Modal onClose={() => { updateStatus({ restoreModal: false }) }} show={status.restoreModal} >
				<div className='mt2 mb1'>
					<h1 className='title m0 p0 text-center'>コレクションを表示する</h1>
					<p className='ml-auto mr-auto text-center' style={{ maxWidth: '500px' }}>
						特定のコレクションとそれに紐づくNFTを表示できるようにします。
						<br />
						コレクションの管理者以外でも
						<br />
						他のユーザーからは閲覧することができるようになります。

					</p>

					<div className="flex center">
						<ButtonPrimary style={{ width: '250px', marginTop: '2rem' }} onClick={restoreCollection}>
							表示する
						</ButtonPrimary>
					</div>
					<div className="flex center">
						<ButtonPrimary style={{ width: '250px', marginTop: '1rem', backgroundColor: "#24262f" }} onClick={() => { updateStatus({ restoreModal: false }) }}>キャンセル</ButtonPrimary>
					</div>
				</div>
			</Modal>

			<Modal onClose={() => { updateStatus({ stopModal: false }) }} show={status.stopModal} >
				<div className='mt2 mb1'>
					<h1 className='title m0 p0 text-center'>コレクションを非表示にする</h1>
					<p className='ml-auto mr-auto text-center' style={{ maxWidth: '500px' }}>
						コレクションを非表示にする
						特定のコレクションとそれに紐づくNFTを表示しないようにします。
						<br />
						コレクションの管理者は閲覧することはできますが、
						<br />
						他のユーザーからは閲覧することができなくなります。

					</p>
					<div className="flex center">
						<ButtonPrimary style={{ width: '250px', marginTop: '2rem' }} onClick={hideCollection}>
							非表示にする
						</ButtonPrimary>
					</div>
					<div className="flex center">
						<ButtonPrimary style={{ width: '250px', marginTop: '1rem', backgroundColor: "#24262f" }} onClick={() => { updateStatus({ stopModal: false }) }}>
							キャンセル
						</ButtonPrimary>
					</div>
				</div>
			</Modal>

			<Modal onClose={() => { updateStatus({ acceptBadgeModal: false }) }} show={status.acceptBadgeModal} >
				<div className='mt2 mb1'>
					<h1 className='title m0 p0 text-center'>バッジ申請の承認</h1>
					<p className='ml-auto mr-auto text-center' style={{ maxWidth: '500px' }}>
						このコレクションのバッジ申請を承認しますか？
					</p>
					<div className="flex center">
						<ButtonPrimary style={{ width: '250px', marginTop: '2rem' }} onClick={acceptBadge}>
							承認する
						</ButtonPrimary>
					</div>
					<div className="flex center">
						<ButtonPrimary style={{ width: '250px', marginTop: '1rem', backgroundColor: "#24262f" }} onClick={() => { updateStatus({ acceptBadgeModal: false }) }}>
							キャンセル
						</ButtonPrimary>
					</div>
				</div>
			</Modal>

			<Modal onClose={() => { updateStatus({ rejectBadgeModal: false }) }} show={status.rejectBadgeModal}>
				<div className='mt2 mb1'>
					<h1 className='title m0 p0 text-center'>バッジ申請の拒否</h1>
					<InputField type="textarea" label={"拒否の理由"} value={status.rejectreason} errorLabel={status.reasonerror} onChange={(e) => { updateStatus({ rejectreason: e.target.value }) }}></InputField>
					<div className="flex center">
						<ButtonPrimary style={{ width: '250px', marginTop: '2rem' }} onClick={rejectBadge}>
							拒否する
						</ButtonPrimary>
					</div>
					<div className="flex center">
						<ButtonPrimary style={{ width: '250px', marginTop: '1rem', backgroundColor: "#24262f" }} onClick={() => { updateStatus({ rejectBadgeModal: false }) }}>
							キャンセル
						</ButtonPrimary>
					</div>
				</div>
			</Modal>

			<Modal onClose={() => { updateStatus({ deleteBadgeModal: false }) }} show={status.deleteBadgeModal} >
				<div className='mt2 mb1'>
					<h1 className='title m0 p0 text-center'>バッジの削除</h1>
					<p className='ml-auto mr-auto text-center' style={{ maxWidth: '500px' }}>このコレクションのバッジを削除しますか？</p>
					<div className="flex center">
						<ButtonPrimary style={{ width: '250px', marginTop: '2rem' }} onClick={deleteBadge}>
							削除する
						</ButtonPrimary>
					</div>
					<div className="flex center">
						<ButtonPrimary style={{ width: '250px', marginTop: '1rem', backgroundColor: "#24262f" }} onClick={() => { updateStatus({ deleteBadgeModal: false }) }}>
							キャンセル
						</ButtonPrimary>
					</div>
				</div>
			</Modal>

			<Modal onClose={() => { updateStatus({ addBadgeModal: false }) }} show={status.addBadgeModal} >
				<div className='mt2 mb1'>
					<h1 className='title m0 p0 text-center'>バッジ付与</h1>
					<p className='ml-auto mr-auto text-center' style={{ maxWidth: '500px' }}>
						このコレクションにバッジを付与しますか？
					</p>
					<div className="flex center">
						<ButtonPrimary style={{ width: '250px', marginTop: '2rem' }} onClick={addBadge}>
							付与する
						</ButtonPrimary>
					</div>
					<div className="flex center">
						<ButtonPrimary style={{ width: '250px', marginTop: '1rem', backgroundColor: "#24262f" }} onClick={() => { updateStatus({ addBadgeModal: false }) }}>
							キャンセル
						</ButtonPrimary>
					</div>
				</div>
			</Modal>
		</Layout>
	)
}

export default CollectionDetail;



const StyledContainer = styled.div`
	padding: 2rem 0;
	display: flex;
	flex-direction: column;
	padding-top: 80px;
	justify-content: center;
	@media (max-width: 1024px) {
		padding: 1rem 0;
	}
	@media (max-width: 768px) {
		padding: 160px 0 60px 0;
	}
	min-height: 100vh;
	
`

const StyledPanel = styled.div<{ darkMode: boolean }>`
	border-radius: 2rem;
	background-color: ${({ theme }) => theme.boxColor};
	padding: 2rem 2rem;
	width: 100%;
	max-width: 960px;
	color: ${({ theme }) => theme.text};
	margin: 0 auto 2rem;
	@media (max-width: 768px) {
		padding: 0;
	}
	.title{
		color: ${({ theme, darkMode }) => darkMode ? theme.white : theme.black};
		text-align: center;
		font-size: 2rem;
		margin: 0;
	}
	a{
		text-align: center;
		color: #6b9beb;
	}
	p{
		word-break: break-all;
	}
	@media (max-width: 768px) {
		width: 100%;
		padding: 2rem 1rem;
		margin: 1rem auto 1rem;
		a{
			display: block;
		}
		.link{
			text-align: center;
		}
	}
	.banner {
		width:100%;
		height: 220px;
		object-fit: cover;
	}
	.avatar {
		width: 65px;
		height: 65px;
		object-fit: cover;
		border-radius: 8px;
	}
`
