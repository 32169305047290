import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import decode from "jwt-decode";
import styled, { ThemeContext } from 'styled-components'
import InputField from '../../components/InputField'
import { ButtonPrimary } from '../../components/components'
import useStore from '../../useStore'
import { Now, tips, validateEmail, validatePassword, validateUsername } from '../../util'

import logo from '../../assets/img/logo.png'
import logoblack from '../../assets/img/logoblack.png'

interface LoginInterface {
	name: string
	password: string
	errorname: string
	errorpassword: string
	error: string
}

const Login = () => {
	// @ts-ignore
	const { darkMode, logined, update, call } = useStore()
	const history = useHistory();
	const theme = useContext(ThemeContext)

	const [status, setStatus] = React.useState<LoginInterface>({
		name: '',
		password: '',
		error: '',
		errorname: '',
		errorpassword: '',
	})

	const updateStatus = (params: Partial<LoginInterface>) => setStatus({ ...status, ...params })

	const login = async () => {
		try {
			const name = status.name;
			const password = status.password;
			updateStatus({ error: "", errorname: "", errorpassword: "" });
			if (name.trim() === "" || (!validateEmail(name) && !validateUsername(name))) {
				return updateStatus({ error: "", errorpassword: "", errorname: "正しいメールアドレスまたは名前の形式を入力してください" });
			}
			if (password.trim() === "") {
				return updateStatus({ error: "", errorpassword: "Format error", errorname: "" });
			}
			const result = await call("/api/admin/login", {
				email: name,
				password: password,
			})
			if (result) {
				switch (result['message']) {
					case "success": {
						const token = result['token'];
						var data = decode(token) as any;
						update({ currentAccountMail: data?.admin, token: token, logined: true, lasttime: Now() });
						tips("success", "ログイン成功");
						setTimeout(() => { history.push("/"); }, 500);
						break;
					}
					case "Please enter all required data.": updateStatus({ errorpassword: "", errorname: "", error: "必要なデータをすべて入力してください。" }); break;
					case "No exists user.": updateStatus({ errorpassword: "", errorname: "", error: "パスワードまたはユーザー名/メールアドレスが間違っています" }); break;
					case "No match password.": updateStatus({ errorpassword: "", errorname: "", error: "パスワードまたはユーザー名/メールアドレスが間違っています" }); break;
					case "internal error": updateStatus({ errorpassword: "", errorname: "", error: "Server error" }); break;
				}
			}
		} catch (ex) {
			return tips("error", "不明なエラー")
		}
	}

	React.useEffect(() => {
		if (logined) history.push("/")
	}, [logined])

	return (
		<StyledMain>
			<div style={{ backgroundImage: 'url(back.webp)', backgroundSize: '200% 150%', backgroundPosition: '45% 0%' }}>
				<StyledContainer className="container">
					<StyledLoginPanel darkMode={darkMode}>
						<div className="flex center middle">
							<div>
								<img src={darkMode ? logo : logoblack} className="logo" alt="logo" />
							</div>
							<h2 style={{ color: theme.text }}>admin</h2>
						</div>
						<h3 className='title'>ログイン</h3>
						<InputField label="メールアドレス" errorLabel={status.errorname} value={status.name} onChange={(e) => { updateStatus({ name: e.target.value }) }} placeholder="AAA@gmail.com"></InputField>
						<InputField label="パスワード" type="password" errorLabel={status.errorpassword} value={status.password} onChange={(e) => { updateStatus({ password: e.target.value }) }} placeholder=''></InputField>
						<div className="flex center mt3 mb3">
							<ButtonPrimary style={{ width: '200px' }} onClick={login}>サインイン</ButtonPrimary>
						</div>
						{
							status.error !== "" && <p style={{ textAlign: 'center', color: 'red' }}>{status.error}</p>
						}
					</StyledLoginPanel>
				</StyledContainer>
			</div>
		</StyledMain>
	)
}

export default Login;

const StyledMain = styled.div`
	background-color:  ${({ theme }) => (theme.bgColor2)};
	color:  ${({ theme }) => (theme.text)};
`

const StyledContainer = styled.div`
	padding: 3rem 0 8rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	@media (max-width: 1024px) {
		padding: 1rem 0;
	}
	@media (max-width: 768px) {
		padding: 0;
	}
	min-height: 100vh;
`

const StyledLoginPanel = styled.div<{ darkMode: boolean }>`
	border-radius: 2rem;
	background-color: ${({ theme }) => theme.boxColor};
	padding: 2rem 5rem;
	width: 90%;
	max-width: 600px;
	color: ${({ theme }) => theme.text};
	margin: 3rem auto;
	.title{
		color: ${({ theme, darkMode }) => darkMode ? theme.white : theme.black};
		text-align: center;
		font-size: 2rem;
		margin: 0;
	}
	a{
		text-align: center;
		color: #6b9beb;
	}
	@media (max-width: 768px) {
		width: 100%;
		padding: 2rem 1rem;
		margin: 1.5rem auto 5rem;
		a{
			display: block;
		}
		.link{
			text-align: center;
		}
	}
	.logo{
		cursor: pointer;
		width: 240px;
		height: auto;
		@media (max-width: 768px) {
			margin: 0 8px;
			width: 150px;
		}
	}
`