import styled from 'styled-components'

export const ButtonPrimary = styled.button`
	padding: 10px 0;
	border-radius: 50px;
	cursor: pointer;
	user-select: none;
	font-size: 1rem;
	border: none;
	outline: none;
	font-weight: 700;
	background-color: ${({theme}) => theme.buttonPrimary};
	color: ${({ theme }) => theme.text};
	display: flex;
	flex-direction: row;
	align-items: center;
	align-content: center;
	justify-content: center;
	:hover:not(:disabled) {
		filter: brightness(0.8);
	}
	:disabled {
		background-color: ${({ theme }) => theme.disabled};
		color: ${({ theme }) => theme.textGrey};
		cursor: auto;
	}
`
export const ButtonSecondary = styled.button`
	padding: 10px 0;
	border-radius: 50px;
	cursor: pointer;
	user-select: none;
	font-size: 1rem;
	border: 1px solid	${({ theme }) => theme.link};
	outline: none;
	font-weight: 700;
	background-color: transparent;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	align-content: center;
	color: ${({ theme }) => theme.text};
	:hover {
		border: 1px solid ${({ theme }) => theme.text};
	}
`
export const ButtonBorder = styled.button`
			color:	${({theme}) => theme.textGrey};
			cursor: pointer;
			border-radius: 50px;
			border: 1px solid	${({theme}) => theme.textGrey};
			padding: 8px 1rem;
			user-select: none;
			background-color: transparent;
			&:hover{
				border-color: ${({theme}) => theme.textGrey};
				color:	${({theme}) => theme.textGrey};
				background-color: rgba(0, 0, 0, 0.1);
			}
`