import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { useQuery } from "@apollo/client";
import { GET_NFTS, GET_COLLECTION_INFO } from '../../graphql/index';
import Search from '../../components/Search'
import AdminLayout from '../../components/Layout/Layout'
import { ButtonPrimary } from '../../components/components'
import Modal from '../../components/Modal'
import useStore from '../../useStore'
import { copyToClipboard, ellipsis, isByte32String, tips, toChecksumAddress } from '../../util';

import banner from '../../assets/img/banner.webp'
import avatar from '../../assets/img/avatar.png'

interface NFTListInterface {
	keyword: string
	selectedCollection: string
	selectedOwner: string
	selectedNft: string
	nfts: NFTSearchResult[]
	collectionInfos: Collection[]
	showPickModal: boolean
	showHideModal: boolean
	showRestoreModal: boolean
}


const NFTList = () => {
	const { darkMode, token, logined, call } = useStore()
	const history = useHistory();

	const [status, setStatus] = React.useState<NFTListInterface>({
		keyword: '',
		selectedCollection: '',
		selectedOwner: '',
		selectedNft: '',
		nfts: [],
		showPickModal: false,
		showHideModal: false,
		showRestoreModal: false,
		collectionInfos: []
	})

	const updateStatus = (params: Partial<NFTListInterface>) => setStatus({ ...status, ...params })

	const { data: nftInfo, loading: infoLoading, error: infoError } = useQuery(GET_NFTS, {
		variables: {
			query: isByte32String(status.keyword) ? '' : toChecksumAddress(status.keyword) ? '' : status.keyword,
			tokenid: isByte32String(status.keyword) ? status.keyword : '',
			limit: 0,
			page: 0,
			price1: 0,
			price2: 0,
			owner: '',
			salestatus: '',
			searchsymbol: "",
			sort: "latest",
			symbols: "",
			nftcollection: toChecksumAddress(status.keyword) ? status.keyword : '',
		},
		fetchPolicy: 'network-only'
	})

	React.useEffect(() => {
		if (infoLoading || infoError) return;
		const data = nftInfo?.getNFTs as NFTSearchResult[];
		if (!data) return;
		updateStatus({ nfts: data })
	}, [nftInfo, infoLoading, infoError])

	//collection
	const { data: collectionInfo, loading: collectionLoading, error: collectionError } = useQuery(GET_COLLECTION_INFO, {
		variables: { name: '' },
		fetchPolicy: 'network-only'
	})

	React.useEffect(() => {
		if (collectionLoading || collectionError) return;
		const result = collectionInfo?.getCollectionInfo;
		if (!result) return;
		updateStatus({ collectionInfos: result })
	}, [collectionInfo, collectionLoading, collectionError])

	const restoreNFT = async () => {
		try {
			const result = await call("/api/admin/set-nft-allow", {
				collectionid: status.selectedCollection,
				tokenid: status.selectedNft,
				hide: false
			}, { authorization: token })
			if (result) {
				switch (result['message']) {
					case "success": {
						tips("success", "Restored this nft successfully");
						let nfts = [];
						status.nfts.forEach(nft => {
							if (nft.tokenid === status.selectedNft && nft.nftCollection === status.selectedCollection) nfts.push({ ...nft, hide: false })
							else nfts.push(nft)
						});
						updateStatus({ showRestoreModal: false, nfts })
						break;
					}
					case "internal error": tips("error", "Server error"); break;
				}
			}
		} catch (ex) {
			return tips("error", "Unknown error")
		}

	}
	const pickNFT = async () => {
		try {
			const pick = !(status.nfts.find(nft => nft.tokenid === status.selectedNft && nft.nftCollection === status.selectedCollection).pick || false);
			const result = await call("/api/admin/set-nft-pick", {
				collectionid: status.selectedCollection,
				tokenid: status.selectedNft,
				owner: status.selectedOwner,
				pick: pick
			}, { authorization: token })
			if (result) {
				switch (result['message']) {
					case "success": {
						tips("success", "Set pickup this nft successfully");
						let nfts = [];
						status.nfts.forEach(nft => {
							if (nft.tokenid === status.selectedNft && nft.nftCollection === status.selectedCollection) nfts.push({ ...nft, pick: pick })
							else nfts.push(nft)
						});
						updateStatus({ showPickModal: false, nfts })
						break;
					}
					case "internal error": tips("error", "Server error"); break;
				}
			}
		} catch (ex) {
			return tips("error", "Unknown error")
		}
	}
	const hideNFT = async () => {
		try {
			const result = await call("/api/admin/set-nft-allow", {
				collectionid: status.selectedCollection,
				tokenid: status.selectedNft,
				owner: status.selectedOwner,
				hide: true
			}, { authorization: token })
			if (result) {
				switch (result['message']) {
					case "success": {
						tips("success", "Stoped this nft successfully");
						let nfts = [];
						status.nfts.forEach(nft => {
							if (nft.tokenid === status.selectedNft && nft.nftCollection === status.selectedCollection) nfts.push({ ...nft, hide: true })
							else nfts.push(nft)
						});
						updateStatus({ showHideModal: false, nfts })
						break;
					}
					case "internal error": tips("error", "Server error"); break;
				}
			}
		} catch (ex) {
			return tips("error", "Unknown error")
		}
	}

	React.useEffect(() => {
		if (!logined) history.push("/login")
	}, [logined])

	return (
		<AdminLayout>
			<StyledContainer className="container" >
				<h1 className='text-center' style={{ fontWeight: 'bolder' }}>NFT list</h1>
				<div className="flex center">
					<Search value={status.keyword}
						onValueChange={(value) => { updateStatus({ keyword: value }) }}
						resetValue={() => { updateStatus({ keyword: "" }) }}
						placeholder='コレクション名で検索'
					/>
				</div>
				<StyledListPanel darkMode={darkMode} style={{ marginTop: '2em' }}>
					<div className="list-row header">
						<div>トークンID</div>
						<div>NFT画像</div>
						<div>NFT名</div>
						<div>個数</div>
						<div>コレクション情報</div>
						<div></div>
					</div>
					<div className="list-content">
						{
							status.nfts.map((nft, index) => (
								<div className="list-row" key={index}>
									<div className='cursor' onClick={() => { copyToClipboard(nft.tokenid) }}>{ellipsis(nft.tokenid)}</div>
									<div>
										<img src={nft.image || avatar} className='nft' />
									</div>
									<div onClick={() => { copyToClipboard(nft.name) }}>{ellipsis(nft.name)}</div>
									<div>1</div>
									<div>
										<div className="flex middle">
											<img src={status.collectionInfos.find((collection) => collection.address === nft.nftCollection)?.metadata?.image || banner} className='collection' />
											<div onClick={() => { copyToClipboard(status.collectionInfos.find((collection) => collection.address === nft.nftCollection)?.address) }}>
												{
													status.collectionInfos.find((collection) => collection.address === nft.nftCollection)?.metadata?.name
												}
											</div>
										</div>
									</div>
									<div>
										<div className="flex">
											<ButtonPrimary style={{ width: '100px', padding: '4px 8px', margin: '4px', backgroundColor: '#59d398' }}
												onClick={() => {
													nft.hide ? updateStatus({ showRestoreModal: true, selectedCollection: nft.nftCollection, selectedNft: nft.tokenid }) : updateStatus({ showHideModal: true, selectedCollection: nft.nftCollection, selectedNft: nft.tokenid, selectedOwner: nft.owner })
												}}>
												{
													nft.hide ? "表示" : "非表示"
												}
											</ButtonPrimary>
											<ButtonPrimary style={{ width: '100px', padding: '4px 8px', margin: '4px', backgroundColor: nft.pick ? '#a5a5a5' : '#f56f63' }} onClick={() => { updateStatus({ showPickModal: true, selectedCollection: nft.nftCollection, selectedNft: nft.tokenid }) }}>Pick UP</ButtonPrimary>
										</div>
									</div>
								</div>
							))
						}
					</div>
				</StyledListPanel>
			</StyledContainer>
			<Modal onClose={() => { updateStatus({ showRestoreModal: false }) }} show={status.showRestoreModal} >
				<div className='mt2 mb1'>
					<h1 className='title m0 p0 text-center'>NFTを表示する</h1>
					<p className='ml-auto mr-auto text-center' style={{ maxWidth: '500px' }}>
						NFT保有者とコレクションの管理者以外の ユーザーからも閲覧することができるようになります。
					</p>

					<div className="flex center">
						<ButtonPrimary style={{ width: '250px', marginTop: '2rem' }} onClick={restoreNFT}>
							表示する
						</ButtonPrimary>
					</div>
					<div className="flex center">
						<ButtonPrimary style={{ width: '250px', marginTop: '1rem', backgroundColor: "#24262f" }} onClick={() => { updateStatus({ showRestoreModal: false }) }}>キャンセル</ButtonPrimary>
					</div>
				</div>
			</Modal>
			<Modal onClose={() => { updateStatus({ showHideModal: false }) }} show={status.showHideModal} >
				<div className='mt2 mb1'>
					<h1 className='title m0 p0 text-center'>NFTを非表示にする</h1>
					<p className='ml-auto mr-auto text-center' style={{ maxWidth: '500px' }}>
						NFT保有者とコレクションの管理者は閲覧することはできますが、 他のユーザーからは閲覧することができなくなります。
					</p>

					<div className="flex center">
						<ButtonPrimary style={{ width: '250px', marginTop: '2rem' }} onClick={hideNFT}>
							非表示にする
						</ButtonPrimary>
					</div>
					<div className="flex center">
						<ButtonPrimary style={{ width: '250px', marginTop: '1rem', backgroundColor: "#24262f" }} onClick={() => { updateStatus({ showHideModal: false }) }}>キャンセル</ButtonPrimary>
					</div>
				</div>
			</Modal>
			<Modal onClose={() => { updateStatus({ showPickModal: false }) }} show={status.showPickModal} >
				<div className='mt2 mb1'>
					<h1 className='title m0 p0 text-center'>Pick UP設定</h1>
					<p className='ml-auto mr-auto text-center' style={{ maxWidth: '500px' }}>
						Marketplace client webのホームページに
						<br>
						</br>
						表示するNFTを設定します
					</p>

					<div className="flex center">
						<ButtonPrimary style={{ width: '250px', marginTop: '2rem' }} onClick={pickNFT}>
							設定する
						</ButtonPrimary>
					</div>
					<div className="flex center">
						<ButtonPrimary style={{ width: '250px', marginTop: '1rem', backgroundColor: "#24262f" }} onClick={() => { updateStatus({ showPickModal: false }) }}>キャンセル</ButtonPrimary>
					</div>
				</div>
			</Modal>
		</AdminLayout>
	)
}

export default NFTList;



const StyledContainer = styled.div`
	padding: 2rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	@media (max-width: 1024px) {
		padding: 1rem;
	}
	min-height: 100vh;
	
	.list-content {
		max-height: 50vh;
		overflow-y: auto;
		min-height: 300px;
	}
	.list-row{
		display: flex;
		flex-direction: row;
		align-items: center;
		align-content: center;
		padding: 8px;
		border-bottom: 1px solid ${({ theme }) => theme.strokeColor};
		@media (max-width: 768px) {
			display: block;
			text-align: center;
		}
		>div:nth-child(1) {
			flex: 2;
			display: flex;
			align-items: center;
			@media (max-width: 768px) {
				justify-content: center;
			}
		}
		>div:nth-child(2) {
			flex: 1;
			display: flex;
			align-items: center;
			@media (max-width: 768px) {
				justify-content: center;
			}
		}
		>div:nth-child(3) {
			flex: 2;
		}
		>div:nth-child(4) {
			flex: 1;
		}
		>div:nth-child(5) {
			flex: 3;
			display: flex;
			align-items: center;
			@media (max-width: 768px) {
				justify-content: center;
			}
		}
		>div:nth-child(6) {
			flex: 3;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: flex-end;
			@media (max-width: 768px) {
				justify-content: center;
			}
		}
		&.header {
			font-size: 1.1rem;
			font-weight: bold;
		}
		.nft{
			width: 70px;
			height: 70px;
			border-radius: 8px;
			margin: 8px;
			object-fit: cover;
		}
		.collection {
			width: 50px;
			height: 50px;
			border-radius: 8px;
			margin: 4px 8px;
			object-fit: cover;
		}
	}
`

const StyledListPanel = styled.div<{ darkMode: boolean }>`
	border-radius: 2rem;
	background-color: ${({ theme }) => theme.boxColor};
	padding: 2rem 2rem;
	width: 100%;
	max-width: 960px;
	color: ${({ theme }) => theme.text};
	margin: 1rem auto;
	.title{
		color: ${({ theme, darkMode }) => darkMode ? theme.white : theme.black};
		text-align: center;
		font-size: 2rem;
		margin: 0;
	}
	a{
		text-align: center;
		color: #6b9beb;
	}
	@media (max-width: 768px) {
		width: 100%;
		padding: 2rem 1rem;
		margin: 124px auto 1rem;
		a{
			display: block;
		}
		.link{
			text-align: center;
		}
	}
	.logo{
		cursor: pointer;
		width: 240px;
		height: auto;
		@media (max-width: 768px) {
			margin: 0 8px;
			width: 150px;
		}
	}
`
