import React from 'react';
import { ethers } from 'ethers';
import { useState } from 'react';
import styled from 'styled-components';
import { useQuery } from "@apollo/client";
import { useHistory } from 'react-router-dom';

import Modal from 'components/Modal';
import useStore from '../../useStore';
import Search from '../../components/Search';
import InputField from 'components/InputField';
import CustomCheckbox from '../../components/Checkbox';
import AdminLayout from '../../components/Layout/Layout';
import { Icon } from '../../components/Icon';
import { GET_COLLECTION_INFO } from '../../graphql/index';
import { ButtonPrimary } from '../../components/components';
import { copyToClipboard, tips, toChecksumAddress } from '../../util';

import avatar from '../../assets/img/avatar.png';
import banner from '../../assets/img/banner.webp';

import Abis from "../../blockchain/abi/abis.json";
import { provider } from 'blockchain/providers';

interface CollectionListInterface {
	keyword: string
	checked1: boolean
	checked2: boolean
	checked3: boolean
	checked4: boolean
	collections: Collection[]
}

const CollectionList = () => {
	const { darkMode, logined, token } = useStore()
	const history = useHistory();

	const [status, setStatus] = React.useState<CollectionListInterface>({
		keyword: '',
		checked1: false,
		checked2: false,
		checked3: false,
		checked4: false,
		collections: []
	})

	const updateStatus = (params: Partial<CollectionListInterface>) => setStatus({ ...status, ...params })

	const badgeState = {
		verified: "取得済み",
		rejected: "拒否",
		pending: "申請中",
		"": "未申請"
	}

	//collection
	const { data: collectionInfo, loading: collectionLoading, error: collectionError } = useQuery(GET_COLLECTION_INFO, {
		variables: {
			adminPage: "admin",
			name: toChecksumAddress(status.keyword) ? "" : status.keyword,
			address: toChecksumAddress(status.keyword) ? toChecksumAddress(status.keyword) : "",
			owner: toChecksumAddress(status.keyword) ? toChecksumAddress(status.keyword) : "",
			badge:
				(status.checked1 ? "," : "not,") +
				(status.checked2 ? "pending," : "not,") +
				(status.checked3 ? "rejected," : "not,") +
				(status.checked4 ? "verified," : "not,"),
		},
		fetchPolicy: 'network-only'
	})

	React.useEffect(() => {
		if (collectionLoading || collectionError) return;
		const result = collectionInfo?.getCollectionInfo;
		if (!result) return;
		updateStatus({ collections: result })
	}, [collectionInfo, collectionLoading, collectionError])

	React.useEffect(() => {
		if (!logined) history.push("/login")
	}, [logined])

	return (
		<AdminLayout>
			<StyledContainer className="container" >
				<h1 className='text-center' style={{ fontWeight: 'bolder' }}>
					Collection list
				</h1>

				<div className="flex center">
					<Search value={status.keyword}
						placeholder='コレクション名で検索'
						resetValue={() => { updateStatus({ keyword: "" }) }}
						onValueChange={(value) => { updateStatus({ keyword: value }) }}
					/>
				</div>

				<div className="flex center wrap middle mt1 mb1">
					<div className="flex middle">
						<div><Icon icon="Photo" size={54} /></div>
						<p>バッジステータス</p>
					</div>

					<div className="flex middle">
						<CustomCheckbox checked={status.checked1}
							color='#59d398'
							changeEvent={(checked) => {
								updateStatus({ checked1: checked })
							}}
						/>
						<p className='cursor' onClick={() => { updateStatus({ checked1: !status.checked1 }) }}>未申請</p>
					</div>

					<div className="flex middle">
						<CustomCheckbox checked={status.checked2}
							color='#59d398'
							changeEvent={(checked) => {
								updateStatus({ checked2: checked })
							}}
						/>
						<p className='cursor' onClick={() => { updateStatus({ checked2: !status.checked2 }) }}>申請中</p>
					</div>

					<div className="flex middle">
						<CustomCheckbox checked={status.checked3}
							color='#59d398'
							changeEvent={(checked) => {
								updateStatus({ checked3: checked })
							}}
						/>
						<p className='cursor' onClick={() => { updateStatus({ checked3: !status.checked3 }) }}>拒否</p>
					</div>

					<div className="flex middle">
						<CustomCheckbox checked={status.checked4}
							color='#59d398'
							changeEvent={(checked) => {
								updateStatus({ checked4: checked })
							}}
						/>
						<p className='cursor' onClick={() => { updateStatus({ checked4: !status.checked4 }) }}>取得済み</p>
					</div>
				</div>

				<ImportCollection />

				<StyledListPanel darkMode={darkMode} style={{ marginTop: '1em' }}>
					<div className="list-row header">
						<div>アイコン画像</div>
						<div>カバー画像</div>
						<div>コレクション名</div>
						<div>バッジ</div>
						<div></div>
					</div>

					<div className="list-content">
						{status.collections.map((collection, index) => (
							<div className="list-row" key={"list" + index}>
								<div>
									<img src={collection?.metadata?.image || avatar} className='avatar' />
								</div>

								<div>
									<img src={collection?.metadata?.coverImage || banner} className='banner' />
								</div>

								<div className='cursor' onClick={() => { copyToClipboard(collection?.address) }}>
									{collection?.metadata?.name}
								</div>

								<div>
									{badgeState[collection?.verified.status]}
								</div>

								<div>
									<ButtonPrimary onClick={() => { history.push("/collection/detail/" + collection.address) }}
										style={{ width: '100px', padding: '4px 8px', margin: '4px', backgroundColor: '#59d398' }}
									>
										詳細表示
									</ButtonPrimary>
								</div>
							</div>
						))}
					</div>
				</StyledListPanel>
			</StyledContainer>
		</AdminLayout>
	)
}

const ImportCollection = () => {
	const { token, call, update } = useStore();
	const [openModal, setOpenModal] = useState(false);
	const [collectionAddr, setCollectionAddr] = useState("");
	const [errorLabel, setErrorLabel] = useState("");

	const onOpenModal = () => { setOpenModal(true) }
	const onCloseModal = () => { setOpenModal(false) }
	const onChangeAddr = ({ target }) => { setCollectionAddr(target.value) }

	const importCollection = async () => {
		try {
			setErrorLabel("");
			if (!toChecksumAddress(collectionAddr)) {
				return setErrorLabel("無効な契約住所");
			}

			update({ loading: true });
			const ca = new ethers.Contract(toChecksumAddress(collectionAddr), Abis.NFT, provider);
			const symbol = await ca.symbol();
			const name = await ca.name();

			const result = await call("/api/admin/collection/import", {
				address: toChecksumAddress(collectionAddr), name: name
			}, { authorization: token })

			if (result) {
				switch (result["message"]) {
					case "success": {
						tips("success", "リクエストは正常に送信されました。");
						setCollectionAddr("");
						onCloseModal();
						break;
					}

					case "No match name":
						tips("warning", "コレクション名が一致しません");
						break;

					case "not owner":
						tips("warning", "あなたはコレクションの所有者ではありません");
						break;

					case "exists already":
						tips("warning", "同じコレクションが既に存在します");
						break;

					case "internal error":
						tips("error", "ネットワーク エラー");
						break;
				}
			}

			update({ loading: false });
		} catch (err) {
			update({ loading: false });
			tips("error", "コントラクトが存在しません.");
		}
	}

	return (
		<ImportCollectionPanel>
			<ActiveButton onClick={onOpenModal}>
				インポートコレクション
			</ActiveButton>

			<Modal onClose={onCloseModal} show={openModal}>
				<ImportCollectionContainer>
					<h1 className='title'>
						インポートコレクション
					</h1>

					<InputField required
						value={collectionAddr}
						errorLabel={errorLabel}
						label="Contract Address"
						onChange={onChangeAddr}
					/>

					<div className='import-collection-footer'>
						<ActiveButton onClick={importCollection}>
							輸入
						</ActiveButton>

						<ButtonPrimary onClick={onCloseModal}>
							キャンセル
						</ButtonPrimary>
					</div>
				</ImportCollectionContainer>
			</Modal>
		</ImportCollectionPanel>
	)
}

export default CollectionList;

const StyledContainer = styled.div`
	padding: 2rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	@media (max-width: 1024px) {
		padding: 1rem;
	}
	min-height: 100vh;
	
	.list-content {
		max-height: 50vh;
		overflow-y: auto;
		min-height: 300px;
	}
	.list-row{
		display: flex;
		flex-direction: row;
		align-items: center;
		align-content: center;
		padding: 8px;
		border-bottom: 1px solid ${({ theme }) => theme.strokeColor};
		@media (max-width: 768px) {
			display: block;
			text-align: center;
		}
		div:nth-child(1) {
			flex: 2;
			display: flex;
			align-items: center;
			@media (max-width: 768px) {
				justify-content: center;
			}
		}
		div:nth-child(2) {
			flex: 3;
			display: flex;
			align-items: center;
			@media (max-width: 768px) {
				justify-content: center;
			}
		}
		div:nth-child(3) {
			flex: 3;
		}
		div:nth-child(4) {
			flex: 2;
		}
		div:nth-child(5) {
			flex: 2;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: flex-end;
			@media (max-width: 768px) {
				justify-content: center;
			}
		}
		&.header {
			font-size: 1.1rem;
			font-weight: bold;
		}
		.avatar {
			width: 50px;
			height: 50px;
			border-radius: 8px;
			object-fit: cover;
		}
		.banner {
			width: 90%;
			height: 50px;
			border-radius: 8px;
			margin: 4px 0;
			object-fit: cover;
		}
	}
`

const StyledListPanel = styled.div<{ darkMode: boolean }>`
	width: 100%;
	max-width: 960px;
	border-radius: 2rem;
	color: ${({ theme }) => theme.text};
	background-color: ${({ theme }) => theme.boxColor};
	padding: 2rem 2rem;
	margin: 1rem auto;

	.title{
		color: ${({ theme, darkMode }) => darkMode ? theme.white : theme.black};
		text-align: center;
		font-size: 2rem;
		margin: 0;
	}

	a{
		text-align: center;
		color: #6b9beb;
	}

	@media (max-width: 768px) {
		width: 100%;
		padding: 2rem 1rem;
		margin: 124px auto 1rem;

		a{
			display: block;
		}

		.link{
			text-align: center;
		}
	}

	.logo{
		cursor: pointer;
		width: 240px;
		height: auto;
		@media (max-width: 768px) {
			margin: 0 8px;
			width: 150px;
		}
	}
`

const ActiveButton = styled(ButtonPrimary)({
	backgroundColor: "#59d398",
})

const ImportCollectionPanel = styled.div(({ }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "end",

	width: "100%",
	maxWidth: 960,
	margin: "1rem auto 0px",

	button: {
		paddingLeft: 10,
		paddingRight: 10,
	}
}))

const ImportCollectionContainer = styled.div({
	gap: 10,
	display: "flex",
	flexDirection: "column",

	h1: {
		textAlign: "center",
		marginBottom: "unset",
	},

	".import-collection-footer": {
		gap: 10,
		display: "flex",
		flexDirection: "row",

		button: {
			flex: 1,
		}
	},

	"@media (max-width: 768px)": {
		".import-collection-footer": {
			flexDirection: "column",
		}
	}
})