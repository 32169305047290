import React from 'react';
import { useEffect } from 'react';
import Highcharts from 'highcharts';
import styled from 'styled-components';
import { useQuery } from "@apollo/client";
import { useHistory } from 'react-router-dom';

import useStore from '../../useStore';
import Config from '../../config/config.json';
import Icon from '../../components/Icon';
import { NF, copyToClipboard } from '../../util';
import HighchartsReact from 'highcharts-react-official';
import AdminLayout from '../../components/Layout/Layout';
import { GET_ADMIN_WALLET, GET_PAYLABLE_TOKEN, GET_FEE } from '../../graphql/index';
import { GET_TRADE_INFO, GET_TRADE_CHART, GET_CRYPTO_CHART, GET_TRADE_INFO_SYMBOL } from '../../graphql/index';

interface VolumeSymbolNft {
  feeJpy: number
  feeSymbol: number
  tradeVolumeJpy: number
  tradeVolumeSymbol: number
  symbol: string
}

interface VolumeSymbolCrypto {
  feeJpy: number
  feeSymbol: number
  tradeVolumeJpy: number
  tradeVolumeSymbol: number
  symbol: string
}

interface DashboardInterface {
  paylableTokens: PaylableTokens[]
  nftWallet: string
  exchangeWallet: string
  treasuryWallet: string
  tradeFee: number
  exchangeFee: number
  todayVolume: number
  yesterdayVolume: number
  week1Volume: number
  week2Volume: number
  total1Volume: number
  total2Volume: number
  nftTradeVolumeBySymbol: VolumeSymbolNft[]
  cryptoTradeVolumeBySymbol: VolumeSymbolCrypto[]
  isMobile: boolean
}

const Dashboard = () => {
  const history = useHistory();
  const { logined, token } = useStore()

  const [status, setStatus] = React.useState<DashboardInterface>({
    paylableTokens: [],
    nftWallet: '',
    exchangeWallet: '',
    treasuryWallet: '',
    tradeFee: 0,
    exchangeFee: 0,
    todayVolume: 0,
    yesterdayVolume: 0,
    week1Volume: 0,
    week2Volume: 0,
    total1Volume: 0,
    total2Volume: 0,
    nftTradeVolumeBySymbol: [],
    cryptoTradeVolumeBySymbol: [],
    isMobile: false
  })

  const updateStatus = (params: Partial<DashboardInterface>) => setStatus({ ...status, ...params })


  const [chartOptions, setChartOptions] = React.useState({
    credits: {
      enabled: false
    },

    chart: {
      type: 'line',
      backgroundColor: 'transparent',
      parallelAxes: {
        lineColor: 'red'
      }
    },

    title: {
      text: ''
    },

    xAxis: {
      categories: [],
    },

    yAxis: {
      title: {
        text: 'トレード量(JPY)'
      },

      labels: {
        formatter: function () {
          return this.value + '';
        }
      },

      minorGridLineWidth: 0,
      gridLineColor: 'rgba(255, 255, 255, 0.1)'
    },

    tooltip: {
      crosshairs: true,
      shared: true
    },

    series: [{
      name: 'Date',
      marker: {
        width: 16,
        height: 16,
        lineColor: '#eee',
        fillColor: '#f0675d',
        enabled: false
      },

      lineWidth: 4,
      lineColor: '#e0675d',
      data: [],
      enableMouseTracking: true
    }]
  })

  const [cryptoChartOptions, setCryptoChartOptions] = React.useState({
    credits: {
      enabled: false
    },

    chart: {
      type: 'line',
      backgroundColor: 'transparent',
      parallelAxes: {
        lineColor: 'red'
      }
    },

    title: {
      text: ''
    },

    xAxis: {
      categories: [],
    },

    yAxis: {
      title: {
        text: 'トレード量(JPY)'
      },

      labels: {
        formatter: function () {
          return this.value + '';
        }
      },

      minorGridLineWidth: 0,
      gridLineColor: 'rgba(255, 255, 255, 0.1)'
    },

    tooltip: {
      crosshairs: true,
      shared: true
    },

    series: [{
      marker: {
        width: 16,
        height: 16,
        lineColor: '#eee',
        fillColor: '#59d398',
        enabled: false
      },

      lineWidth: 4,
      lineColor: '#59d398',
      data: [],
      enableMouseTracking: true
    }]
  })

  React.useEffect(() => {
    if (!logined) history.push("/login")
  }, [logined])


  const { data: paylableTokensInfo, loading: paylableTokensLoading, error: paylableTokensError } = useQuery(GET_PAYLABLE_TOKEN, {
    fetchPolicy: 'network-only'
  })

  React.useEffect(() => {
    if (paylableTokensLoading || paylableTokensError) return;
    const data = paylableTokensInfo?.getPaylableToken as PaylableTokens[];
    if (!data) return;
    updateStatus({ paylableTokens: data })
  }, [paylableTokensInfo, paylableTokensLoading, paylableTokensError])

  const { data: feeInfo, loading: feeLoading, error: feeError } = useQuery(GET_FEE, {
    fetchPolicy: 'network-only'
  })

  React.useEffect(() => {
    if (feeLoading || feeError) return;
    const data = feeInfo?.getFee;
    if (!data) return;

    updateStatus({
      tradeFee: data.tradeFee,
      exchangeFee: data.exchangeFee,
    })
  }, [feeInfo, feeLoading, feeError])

  const { data: baseTradeInfo, loading: baseTradeLoading, error: baseTradeError } = useQuery(GET_TRADE_INFO, {
    fetchPolicy: 'network-only',
    variables: {
      token: token
    }
  })

  React.useEffect(() => {
    if (baseTradeLoading || baseTradeError) return;
    const data = baseTradeInfo?.getAdminTradeBase;
    if (!data) return;
    updateStatus({
      todayVolume: data?.today || 0,
      yesterdayVolume: data?.yesterday || 0,
      week1Volume: data?.week || 0,
      week2Volume: data?.week2 || 0,
      total1Volume: data?.total || 0,
      total2Volume: data?.total2 || 0
    })
  }, [baseTradeInfo, baseTradeLoading, baseTradeError])

  const { data: nftTradeSymbolInfo, loading: nftTradeSymbolLoading, error: nftTradeSymbolError } = useQuery(GET_TRADE_INFO_SYMBOL, {
    fetchPolicy: 'network-only',
    variables: {
      token: token
    }
  })

  React.useEffect(() => {
    if (nftTradeSymbolLoading || nftTradeSymbolError) return;
    const data = nftTradeSymbolInfo?.getTradeSymbol;
    if (!data) return;
    console.log(data)
    updateStatus({
      nftTradeVolumeBySymbol: data?.nft || [],
      cryptoTradeVolumeBySymbol: data?.crypto || []
    })
  }, [nftTradeSymbolInfo, nftTradeSymbolLoading, nftTradeSymbolError])

  const { data: marketAdminWalletInfo, loading: marketAdminWalletLoading, error: marketAdminWalletError } = useQuery(GET_ADMIN_WALLET, {
    fetchPolicy: 'network-only'
  })

  React.useEffect(() => {
    if (marketAdminWalletLoading || marketAdminWalletError) return;
    const data = marketAdminWalletInfo?.getAdminWallet as any;
    if (!data) return;
    updateStatus({
      nftWallet: data?.addresses['nft'],
      exchangeWallet: data?.addresses['exchange'],
      treasuryWallet: data?.addresses['treasury']
    })
  }, [marketAdminWalletInfo, marketAdminWalletLoading, marketAdminWalletError])

  const { data: tradeChartInfo, loading: tradeChartLoading, error: tradeChartError } = useQuery(GET_TRADE_CHART, {
    fetchPolicy: 'network-only',
    variables: {
      token: token
    }
  })

  React.useEffect(() => {
    if (tradeChartLoading || tradeChartError) return;
    const data = tradeChartInfo?.getTradeChart as any;
    if (!data) return;
    let chartData = [], categories = [];
    data?.data?.forEach((v, index) => {
      chartData = [Number(Number(v?.tradeVolumeJpy || 0).toFixed(3))].concat(chartData)
      const date = new Date(Number(v?._id));
      // if(index % 4 == 0) {
      categories = [(date.getMonth() + 1) + "/" + date.getDate()].concat(categories)
      // }
      // else {
      // 	categories = [""].concat(categories)
      // }
    })

    const series = chartOptions.series[0];
    setChartOptions({
      ...chartOptions,
      xAxis: {
        categories: categories
      },
      series: [
        {
          ...series,
          data: chartData
        }
      ]
    })
  }, [tradeChartInfo, tradeChartLoading, tradeChartError])

  const { data: cryptoChartInfo, loading: cryptoChartLoading, error: cryptoChartError } = useQuery(GET_CRYPTO_CHART, {
    fetchPolicy: 'network-only',
    variables: { token: token }
  })

  useEffect(() => {
    if (cryptoChartLoading || cryptoChartError) return;
    const data = cryptoChartInfo?.getCryptoChart as any;

    console.log(cryptoChartInfo)
    if (!data) return;
    let chartData = [], categories = [];

    data?.data?.forEach((v, index) => {
      chartData = [Number(Number(v?.tradeVolumeJpy || 0).toFixed(3))].concat(chartData)
      const date = new Date(Number(v?._id));
      // if(index % 4 == 0) {
      categories = [(date.getMonth() + 1) + "/" + date.getDate()].concat(categories)
      // }
      // else {
      // 	categories = [""].concat(categories)
      // }
    })

    const series = cryptoChartOptions.series[0];
    setCryptoChartOptions({
      ...cryptoChartOptions,
      xAxis: {
        categories: categories
      },
      series: [
        {
          ...series,
          data: chartData
        }
      ]
    })
  }, [cryptoChartInfo, cryptoChartLoading, cryptoChartError])

  React.useEffect(() => {
    if (window.innerWidth < 768) {
      updateStatus({ isMobile: true })
    }
  }, [])

  return (
    <AdminLayout>
      <StyledContainer className="container" >
        <div className="row">
          <div className="col-lg-7 col-md-8 col-sm-12">
            <h2>Base Information</h2>

            <div className="row">
              <div className="col-lg-4 col-sm-6 col-6">
                <StyledPanel>
                  <h3 className='p0 m0'>{NF(status.todayVolume)} JPY</h3>
                  <p className='p0 m0 small'>今日のトレード量</p>
                  <p className='p0 m0 small grey'>
                    <span className={status.todayVolume - status.yesterdayVolume >= 0 ? `green` : 'red'}>
                      {status.todayVolume - status.yesterdayVolume > 0 ? "+" : ""}
                      {Number(Number(status.todayVolume - status.yesterdayVolume).toFixed(4))}
                    </span>
                    前日比
                  </p>
                </StyledPanel>
              </div>

              <div className="col-lg-4 col-sm-6 col-6">
                <StyledPanel>
                  <h3 className='p0 m0'>{NF(status.week1Volume)}  JPY</h3>
                  <p className='p0 m0 small'>1週間のトレード量</p>
                  <p className='p0 m0 small grey'>
                    <span className={status.week1Volume - status.week2Volume >= 0 ? `green` : 'red'}>
                      {status.week1Volume - status.week2Volume > 0 ? "+" : ""}
                      {Number(Number(status.week1Volume - status.week2Volume).toFixed(4))}
                    </span>
                    前日比
                  </p>
                </StyledPanel>
              </div>

              <div className="col-lg-4 col-sm-6 col-6">
                <StyledPanel>
                  <h3 className='p0 m0'>{NF(status.total1Volume)} JPY</h3>
                  <p className='p0 m0 small'>合計トレード量</p>
                  <p className='p0 m0 small grey'>
                    <span className={status.total1Volume - status.total2Volume >= 0 ? `green` : 'red'}>
                      {status.total1Volume - status.total2Volume > 0 ? "+" : ""}
                      {Number(Number(status.total1Volume - status.total2Volume).toFixed(4))}
                    </span>
                    前日比
                  </p>
                </StyledPanel>
              </div>
            </div>

            <div className="row mt2">
              <div className="col-12">
                <StyledPanel>
                  <p className='p0'>管理者ウォレットアドレス</p>

                  <div className='row mt1'>
                    <div className="col-lg-3 col-md-4 col-sm-6">
                      <span className='grey'>NFT 管理: </span>
                    </div>

                    <div className="col-lg-9 col-md-8 col-sm-6 flex middle">
                      <span className='m0' style={{ overflow: 'hidden', width: '90%' }}>{status.nftWallet}</span>
                      <div className='cursor' onClick={() => { window.open(Config.explorer + status.nftWallet, "_blank") }}>
                        <Icon icon="Link" size={17} marginLeft={15} />
                      </div>
                    </div>
                  </div>

                  <div className='row mt1'>
                    <div className="col-lg-3 col-md-4 col-sm-6">
                      <span className='grey'>Treasury: </span>
                    </div>

                    <div className="col-lg-9 col-md-8 col-sm-6 flex middle">
                      <span className='m0' style={{ overflow: 'hidden', width: '90%' }}>{status.treasuryWallet}</span>
                      <div className='cursor' onClick={() => { window.open(Config.explorer + status.treasuryWallet, "_blank") }}>
                        <Icon icon="Link" size={17} marginLeft={15} />
                      </div>
                    </div>
                  </div>

                  <div className='row mt1'>
                    <div className="col-lg-3 col-md-4 col-sm-6">
                      <span className='grey'>Exchange: </span>
                    </div>

                    <div className="col-lg-9 col-md-8 col-sm-6 flex middle">
                      <span className='m0' style={{ overflow: 'hidden', width: '90%' }}>{status.exchangeWallet}</span>
                      <div className='cursor' onClick={() => { window.open(Config.explorer + status.exchangeWallet, "_blank") }}>
                        <Icon icon="Link" size={17} marginLeft={15} />
                      </div>
                    </div>
                  </div>
                </StyledPanel>
              </div>
            </div>
          </div>

          <div className="col-lg-5 col-md-8 col-sm-12">
            <h2>Fee</h2>

            <div className="row">
              <div className="col-lg-12">
                <StyledPanel style={{ width: '250px' }}>
                  <p className='p0 m0'><span className='grey'>NFT取引手数料:</span> {status.tradeFee}%</p>
                </StyledPanel>
              </div>
            </div>

            <div className="row mt1">
              <div className="col-lg-12">
                <StyledPanel style={{ width: '250px' }}>
                  <p className='p0 m0'><span className='grey'>暗号資産売買手数料:</span> {status.exchangeFee}%</p>
                </StyledPanel>
              </div>
            </div>
          </div>
        </div>

        <h2 className='mt5'>Trade volume transition (NFT trade)</h2>

        <StyledPanel style={{ borderRadius: '2rem' }}>
          <HighchartsReact
            highcharts={Highcharts}
            options={chartOptions}
          />
        </StyledPanel>

        <h2 className='mt5'>Tokens list (NFT trade)</h2>

        <StyledPanel style={{ borderRadius: '2rem' }}>
          <div className="token-list">
            <div className='token-row th'>
              <div></div>
              <div>トークン名</div>
              <div>合計トレード量(Symbol) </div>
              <div>合計トレード量(JPY) </div>
              <div>合計利益(Symbol) </div>
              <div>合計利益(JPY) </div>
              <div>アドレス</div>
            </div>

            {status.paylableTokens.map((token, index) => (
              <div className="token-row" key={"ntk" + index}>
                <div style={{ textAlign: 'center' }}>
                  <img src={token.icon} alt="icon" style={{ width: '25px', height: '25px', borderRadius: '50%' }} />
                </div>

                <div>
                  {status.isMobile && "トークン名: "}
                  {token.symbol}
                </div>

                <div>
                  {status.isMobile && "合計トレード量(Symbol): "}
                  {Number(Number(status.nftTradeVolumeBySymbol.find(v => { return v.symbol === token.symbol })?.tradeVolumeSymbol || 0).toFixed(4))}
                </div>

                <div>
                  {status.isMobile && "合計トレード量(JPY): "}
                  {Number(Number(status.nftTradeVolumeBySymbol.find(v => { return v.symbol === token.symbol })?.tradeVolumeJpy || 0).toFixed(4))}
                </div>

                <div>
                  {status.isMobile && "合計利益(Symbol): "}
                  {Number(Number(status.nftTradeVolumeBySymbol.find(v => { return v.symbol === token.symbol })?.feeSymbol || 0).toFixed(4))}
                </div>

                <div>
                  {status.isMobile && "合計利益(JPY): "}
                  {Number(Number(status.nftTradeVolumeBySymbol.find(v => { return v.symbol === token.symbol })?.feeJpy || 0).toFixed(4))}
                </div>

                <div style={{ textAlign: 'center' }}>
                  <div style={{ cursor: 'pointer', margin: 'auto', width: '24px' }} onClick={() => { copyToClipboard(token.address) }}>
                    <Icon icon="Copy" size={18} />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </StyledPanel>

        {/* {console.log(status.cryptoTradeVolumeBySymbol, status.nftTradeVolumeBySymbol)} */}
        <h2 className='mt5'>Tokens list (Cryptocurrency trade)</h2>

        <StyledPanel style={{ borderRadius: '2rem' }}>
          <div className="token-list">
            <div className='token-row th'>
              <div></div>
              <div>トークン名</div>
              <div>合計トレード量(Symbol) </div>
              <div>合計トレード量(JPY) </div>
              <div>合計利益(Symbol) </div>
              <div>合計利益(JPY) </div>
              <div>アドレス</div>
            </div>

            {status.paylableTokens.map((token, index) => (
              <div className="token-row" key={"ctk" + index}>
                <div style={{ textAlign: 'center' }}>
                  <img src={token.icon} alt="icon" style={{ width: '25px', height: '25px', borderRadius: '50%' }} />
                </div>

                <div>
                  {status.isMobile && "トークン名: "}
                  {token.symbol}
                </div>

                <div>
                  {status.isMobile && "合計トレード量(Symbol): "}
                  {Number(Number(status.cryptoTradeVolumeBySymbol.find(v => { return v.symbol === token.symbol })?.tradeVolumeSymbol || 0).toFixed(4))}
                </div>

                <div>
                  {status.isMobile && "合計トレード量(JPY): "}
                  {Number(Number(status.cryptoTradeVolumeBySymbol.find(v => { return v.symbol === token.symbol })?.tradeVolumeJpy || 0).toFixed(4))}
                </div>

                <div>
                  {status.isMobile && "合計利益(Symbol): "}
                  {Number(Number(status.cryptoTradeVolumeBySymbol.find(v => { return v.symbol === token.symbol })?.feeSymbol || 0).toFixed(4))}
                </div>

                <div>
                  {status.isMobile && "合計利益(JPY): "}
                  {Number(Number(status.cryptoTradeVolumeBySymbol.find(v => { return v.symbol === token.symbol })?.feeJpy || 0).toFixed(4))}
                </div>

                <div style={{ textAlign: 'center' }}>
                  <div style={{ cursor: 'pointer', margin: 'auto', width: '24px' }} onClick={() => { copyToClipboard(token.address) }}>
                    <Icon icon="Copy" size={18} />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </StyledPanel>

        <h2 className='mt5'>Trade volume transition (Cryptocurrency trade)</h2>

        <StyledPanel style={{ borderRadius: '2rem' }}>
          <HighchartsReact
            highcharts={Highcharts}
            options={cryptoChartOptions}
          />
        </StyledPanel>
      </StyledContainer>
    </AdminLayout>
  )
}

const StyledContainer = styled.div`
	padding: 3rem;
	display: flex;
	flex-direction: column;
	@media (max-width: 1024px) {
		padding: 1rem 0;
	}
	min-height: 100vh;
`

const StyledPanel = styled.div`
	border-radius: 8px;
	background-color:  ${({ theme }) => theme.boxColor};
	padding: 1rem;
	margin: 4px 0;
	overflow-x: hidden;
	.green{
		color: #34c38f;
	}
	.red {
		color: #f46a6a;
	}
	.small{
		font-size: 0.9rem;
	}
	.grey {
		color: #74788d;
	}
	.token-list {
		overflow-x: auto;
	}
	.token-row {
		display: flex;
		align-items: center;
		padding: 1rem 0;
		border-bottom: 1px solid #666;
		@media (max-width: 768px) {
			display: block;
			text-align: center;
			&.th {
				display: none;
			}
		}
		div {
			&:nth-child(1) {flex: 1;}
			&:nth-child(2) {flex: 2;}
			&:nth-child(3) {flex: 2;}
			&:nth-child(4) {flex: 2;}
			&:nth-child(5) {flex: 2;}
			&:nth-child(6) {flex: 2;}
			&:nth-child(7) {flex: 1;}
		}
	}
`

export default Dashboard;