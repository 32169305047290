import React from 'react'
import styled from 'styled-components';
import LoadingBar from "react-top-loading-bar";

import Header from './Header';

const Layout = ({ children }: any) => {
  const [progress, setProgress] = React.useState(0);

  React.useLayoutEffect(() => {
    setProgress(100);
  }, []);

  return (
    <StyledMain>
      <Header />

      <div className='body'>
        <LoadingBar height={3}
          color="rgb(42 108 143)"
          progress={progress}
          waitingTime={500}
          loaderSpeed={500}
          style={{ userSelect: 'none' }}
        />

        <div className='ml-auto mr-auto w100'>
          {children}
        </div>
      </div>
    </StyledMain>
  )
}

const StyledMain = styled.div`
  display: flex;
  flex-direction: row;
  color:  ${({ theme }) => (theme.text)};
	background-color:  ${({ theme }) => (theme.bgColor2)};

	@media (max-width: 1024px) {
		display: block;
	}

	.body {
		width: 100%;
    margin-left: 300px;
    
    @media (max-width: 1153px) {
      margin-left: 26%;
		}
    
		@media (max-width: 1024px) {
			margin-left: 0;
			padding: 1rem;
		}
	}
`

export default Layout;