import { ethers } from 'ethers';
import { provider, supportChainId } from './providers';
import Abis from "./abi/abis.json";
import { toChecksumAddress } from './utils';
import { Contract, Provider, setMulticallAddress } from 'ethers-multicall';


/* ----------- multicall helpers ----------- */
setMulticallAddress(250, "0xaeF5373b058DceacedF2995005A86aDfE860c4B4");
setMulticallAddress(4002, "0x2baadf55fb2177e8d13708a7f09b966f671555eb");
setMulticallAddress(421613, "0x6B3a27944A73cB7a8a12aA6C45d196732e1E3543");
setMulticallAddress(11155111, "0x558A7Ff553A446E2612bB1E7bFe34eB24C842843");
setMulticallAddress(80001, "0x345708d115d47CFd3AB93a40bDa2f36a2efD21be");
setMulticallAddress(137, "0x40075aDc41C8a9228558E45cCC01849A0Ee5FdC6");

const multicallProvider = new Provider(provider, supportChainId);

const multicallHelper = async (calls: any[]) => {
	let results: any[] = [];
	for (let i = 0; i < calls?.length; i += 100) {
		const sCalls = calls.slice(i, i + 100);
		const res = await multicallProvider.all(sCalls);
		results = [...results, ...res];
	}

	return results;
};

/* ----------- marketplace contract ----------- */

const getTokenContract = (address: string, signer) => {
	return new ethers.Contract(toChecksumAddress(address), Abis.TestToken, signer);
}
const getTokenContract_m = (address: string) => {
	try {
		if (!address) return null;
		return new Contract(toChecksumAddress(address), Abis.TestToken);
	} catch (err) {
		return null
	}
}

const getERC20Token = (address: string) => {
	return new ethers.Contract(toChecksumAddress(address), Abis.TestToken, provider);
}

const getNFTContract = (address: string, signer) => {
	return new ethers.Contract(toChecksumAddress(address), Abis.NFT, signer);
};

const getMarketplaceContract = (address: string, signer) => {
	return new ethers.Contract(toChecksumAddress(address), Abis.Marketplace, signer);
};

export {
	getTokenContract,
	getMarketplaceContract,
	getNFTContract,
	getTokenContract_m,
	getERC20Token,
	multicallHelper,
	multicallProvider,
	provider
}