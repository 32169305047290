import React from 'react'
import { ethers } from 'ethers';
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { useQuery } from "@apollo/client";
import Search from '../../components/Search'
import useStore from '../../useStore'
import { Icon } from '../../components/Icon'
import CustomCheckbox from '../../components/Checkbox'
import AdminLayout from '../../components/Layout/Layout'
import { ButtonPrimary } from '../../components/components'
import { GET_USER_INFO } from '../../graphql/index';
import { ellipsis } from '../../util';

import avatar from '../../assets/img/avatar.png'


interface UserListInterface {
	keyword: string
	checked1: boolean
	checked2: boolean
	checked3: boolean
	checked4: boolean
	users: UserDataObject[]
}


const UserList = () => {
	// @ts-ignore

	const { darkMode, logined, token, update } = useStore()
	const history = useHistory();

	const [status, setStatus] = React.useState<UserListInterface>({
		keyword: '',
		checked1: false,
		checked2: false,
		checked3: false,
		checked4: false,
		users: []
	})
	const updateStatus = (params: Partial<UserListInterface>) => setStatus({ ...status, ...params })

	const badgeState = {
		verified: "取得済み",
		rejected: "拒否",
		pending: "申請中",
		"": "未申請"
	}

	React.useEffect(() => {
		if (!logined) history.push("/login")
	}, [logined])

	const { data: userInfo, loading: infoLoading, error: infoError } = useQuery(GET_USER_INFO, {
		variables: {
			username: !ethers.utils.isAddress(status.keyword) ? status.keyword : "",
			address: ethers.utils.isAddress(status.keyword) ? status.keyword : "",
			badge:
				(status.checked1 ? "," : "not,") +
				(status.checked2 ? "pending," : "not,") +
				(status.checked3 ? "rejected," : "not,") +
				(status.checked4 ? "verified," : "not,"),
			token: token
		},
		fetchPolicy: 'network-only'
	});
	React.useEffect(() => {
		if (infoLoading || infoError) {
			return;
		}
		const data = userInfo.getUsersInfo;
		updateStatus({ users: data })
	}, [userInfo, infoLoading, infoError]);


	return (
		<AdminLayout>
			<StyledContainer className="container" >
				<h1 className='text-center' style={{ fontWeight: 'bolder' }}>User list</h1>
				<div className="flex center">
					<Search value={status.keyword}
						onValueChange={(value) => { updateStatus({ keyword: value }) }}
						resetValue={() => { updateStatus({ keyword: "" }) }}
						placeholder='ユーザーネームまたはメールアドレスで検索'
					/>
				</div>
				<div className="flex center wrap middle mt1 mb1">
					<div className="flex middle">
						<div><Icon icon="Photo" size={54} /></div>
						<p className='m0 p0'>バッジステータス</p>
					</div>
					<div className="flex middle">
						<CustomCheckbox checked={status.checked1}
							color='#59d398'
							changeEvent={(checked) => {
								updateStatus({ checked1: checked })
							}}
						/>
						<p className='cursor' onClick={() => { updateStatus({ checked1: !status.checked1 }) }}>未申請</p>
					</div>
					<div className="flex middle">
						<CustomCheckbox checked={status.checked2}
							color='#59d398'
							changeEvent={(checked) => {
								updateStatus({ checked2: checked })
							}}
						/>
						<p className='cursor' onClick={() => { updateStatus({ checked2: !status.checked2 }) }}>申請中</p>
					</div>
					<div className="flex middle">
						<CustomCheckbox checked={status.checked3}
							color='#59d398'
							changeEvent={(checked) => {
								updateStatus({ checked3: checked })
							}}
						/>
						<p className='cursor' onClick={() => { updateStatus({ checked3: !status.checked3 }) }}>拒否</p>
					</div>
					<div className="flex middle">
						<CustomCheckbox checked={status.checked4}
							color='#59d398'
							changeEvent={(checked) => {
								updateStatus({ checked4: checked })
							}}
						/>
						<p className='cursor' onClick={() => { updateStatus({ checked4: !status.checked4 }) }}>取得済み</p>
					</div>
				</div>
				<StyledListPanel darkMode={darkMode} style={{ marginTop: '1em' }}>
					<div className="list-row header">
						<div></div>
						<div>ユーザー名</div>
						<div>メールアドレス</div>
						<div>アドレス</div>
						<div>作成日</div>
						<div>バッジ</div>
						<div>  </div>
					</div>
					<div className="list-content">
						{
							status.users && status.users?.map((user: UserDataObject, index: number) => (
								<div className="list-row" key={"u" + index}>
									<div>
										<img src={user?.avatar_img || avatar} className='avatar' />
									</div>
									<div>{user?.name}</div>
									<div>{user?.email}</div>
									<div>{ellipsis(user?.address)}</div>
									<div style={{ fontSize: '0.9rem' }}>{new Date(user?.created * 1000).toLocaleDateString()}</div>
									<div>{badgeState[user?.verified.status]}</div>
									<div>
										<ButtonPrimary
											style={{ width: '100px', padding: '4px 8px', margin: '4px', backgroundColor: '#59d398' }}
											onClick={() => { history.push("/user/detail/" + user.address) }}
										>
											詳細表示
										</ButtonPrimary>
									</div>
								</div>
							))
						}
					</div>
				</StyledListPanel>
			</StyledContainer>
		</AdminLayout>
	)
}
export default UserList;


const StyledContainer = styled.div`
	padding: 2rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	@media (max-width: 1024px) {
		padding: 1rem;
	}
	min-height: 100vh;
	
	.list-content {
		max-height: 50vh;
		overflow-y: auto;
		min-height: 300px;
	}
	.list-row{
		display: flex;
		flex-direction: row;
		align-items: center;
		align-content: center;
		padding: 8px;
		border-bottom: 1px solid ${({ theme }) => theme.strokeColor};
		@media (max-width: 768px) {
			display: block;
			text-align: center;
		}
		div:nth-child(1) {
			flex: 1;
			display: flex;
			align-items: center;
			@media (max-width: 768px) {
				justify-content: center;
			}
		}
		div:nth-child(2) {
			flex: 2;
		}
		div:nth-child(3) {
			flex: 3;
		}
		div:nth-child(4) {
			flex: 2;
		}
		div:nth-child(5) {
			flex: 1;
		}
		div:nth-child(6) {
			flex: 1;
		}
		div:nth-child(7) {
			flex: 2;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: flex-end;
			@media (max-width: 768px) {
				justify-content: center;
			}
		}
		&.header {
			font-size: 1.1rem;
			font-weight: bold;
		}
		.avatar {
			width: 50px;
			height: 50px;
			border-radius: 50%;
			object-fit: cover;
		}
	}
`

const StyledListPanel = styled.div<{ darkMode: boolean }>`
	border-radius: 2rem;
	background-color: ${({ theme }) => theme.boxColor};
	padding: 2rem 2rem;
	width: 100%;
	max-width: 960px;
	color: ${({ theme }) => theme.text};
	margin: 1rem auto;
	.title{
		color: ${({ theme, darkMode }) => darkMode ? theme.white : theme.black};
		text-align: center;
		font-size: 2rem;
		margin: 0;
	}
	a{
		text-align: center;
		color: #6b9beb;
	}
	@media (max-width: 768px) {
		width: 100%;
		padding: 2rem 1rem;
		margin: 124px auto 1rem;
		a{
			display: block;
		}
		.link{
			text-align: center;
		}
	}
	.logo{
		cursor: pointer;
		width: 240px;
		height: auto;
		@media (max-width: 768px) {
			margin: 0 8px;
			width: 150px;
		}
	}
`
