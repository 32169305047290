import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import useStore from '../../useStore'
import InputField from '../../components/InputField'
import AdminLayout from '../../components/Layout/Layout'
import { ButtonPrimary } from '../../components/components'
import { tips, validateEmail, validatePassword  } from '../../util'

interface AdminAddInterface {
	name: string
	password: string
	errorname: string
	errorpassword: string
	password2: string
	errorpassword2: string
	error: string
}


const AdminAdd = () => {
	// @ts-ignore
	
	const {darkMode, logined, token, call, update} = useStore()
	const history = useHistory();
		
	const [status, setStatus] = React.useState<AdminAddInterface>({
		name: '',
		password: '',
		error: '',
		errorname: '',
		errorpassword: '',
		password2: '',
		errorpassword2: ''
	})
	const updateStatus = (params: Partial<AdminAddInterface>) => setStatus({ ...status, ...params })

	const addAdmin = async () => {
		try {
			const name = status.name;
			const password = status.password;
			const confirmpassword = status.password2;
			updateStatus({error: "", errorname: "", errorpassword: "", errorpassword2: ""})
			if(name.trim() === "" || !validateEmail(name)) return updateStatus({errorname: "正しいメールアドレスの形式でご入力ください", error: "", errorpassword: "", errorpassword2: ""}); 
			if(password.trim() === "" || !validatePassword(password))  return updateStatus({ error: "", errorpassword: "上記のパスワードの形式でご入力ください", errorpassword2: "", errorname: ""}); 
			if(confirmpassword.trim() === "" || !validatePassword(confirmpassword))  return updateStatus({error: "", errorpassword: "", errorpassword2: "上記のパスワードの形式でご入力ください", errorname: ""});
			if(password !== confirmpassword) return updateStatus({ error: "", errorpassword: "", errorpassword2: "上記のパスワードの形式でご入力ください", errorname: ""});
			update({loading: true})
			const result = await call("/api/admin/create", {
				email: name,
				password: password
			}, {authorization: token})
			if(result){
				switch(result['message']) {
					case "success": {
						tips("success", "正常に追加されました"); setTimeout(() => {history.push("/admin/list"); }, 500); break;
					}
					case "Please enter all required data.": updateStatus({error: "必要なデータをすべて入力してください。"}); break;
					case "Not exists user": updateStatus({error: "ユーザーが存在しません。"}); break;
					case "Not root admin": updateStatus({error: "このアクションにはルート権限が必要です"}); break;
					case "Already exists same email": updateStatus({error: "同じ管理者が既に存在します"}); break;
					case "internal error": updateStatus({error: "サーバーエラー"}); break;
				}
			}
			update({loading: false})
		} catch(ex) {
			update({loading: false})
			tips("error", "不明なエラー")
		}
	}

	
	React.useEffect(() => {
		if(!logined) history.push("/login")
	}, [logined])

	
	return (
		<AdminLayout>
			<StyledContainer className="container">
				<StyledListPanel darkMode={darkMode}>
						<h3 className='title m0 p0'>Add admin</h3>
						<InputField label="メールアドレス" errorLabel={status.errorname} value={status.name} onChange={(e) => {updateStatus({name: e.target.value})}} placeholder="AAA@gmail.com"></InputField>
						<InputField label="パスワード" type="password" errorLabel={status.errorpassword} value={status.password} onChange={(e) => {updateStatus({password: e.target.value})}}  placeholder=''></InputField>
						<InputField label="パスワード再入力" type="password" errorLabel={status.errorpassword2} value={status.password2} onChange={(e) => {updateStatus({password2: e.target.value})}}  placeholder=''></InputField>
						<div className="flex center mt3 mb3">
							<ButtonPrimary style={{width: '200px'}} onClick = {addAdmin}>
								追加
							</ButtonPrimary>
						</div>
						{
							status.error !== "" && <p style={{textAlign: 'center', color: 'red'}}>{status.error}</p>
						}
				</StyledListPanel>
			</StyledContainer>
		</AdminLayout>
	)
}

export default AdminAdd;



const StyledContainer = styled.div`
	padding: 1rem 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	@media (max-width: 1024px) {
		padding: 1rem 0;
	}
	@media (max-width: 768px) {
		padding: 0;
	}
	min-height: 100vh;
`

const StyledListPanel = styled.div<{darkMode: boolean}>`
	border-radius: 2rem;
	background-color: ${({theme}) => theme.boxColor};
	padding: 2rem 5rem;
	width: 100%;
	max-width: 600px;
	color: ${({theme}) => theme.text};
	margin: 3rem auto;
	.title{
		color: ${({theme, darkMode}) => darkMode ? theme.white:theme.black};
		text-align: center;
		font-size: 2rem;
		margin: 0;
	}
	a{
		text-align: center;
		color: #6b9beb;
	}
	@media (max-width: 768px) {
		width: 100%;
		padding: 2rem 1rem;
		margin: 1rem auto 2rem;
		a{
			display: block;
		}
		.link{
			text-align: center;
		}
	}
`
