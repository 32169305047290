import { ethers } from "ethers"
import { toast }  from 'react-toastify';
import keccak from 'keccak'
import Icon from '../src/components/Icon'
import config from './config/config.json'


export const tips = (type="success", html: string, lang = "en") => {
	let background = "#3772FF";
	let icon = <></>;
	switch(type) {
		case "success":  background = "#3772FF"; icon = <Icon icon = "Info" size={40} height={32} />; break;
		case "info":  background = "#139D61"; icon = <Icon icon = "Info" size={40} height={32} />; break;
		case "warning":  background = "#F56F63"; icon = <Icon icon = "Warning" size={40} height={32} />; break;
		case "error":  background = "#F56F63"; icon = <Icon icon = "Error" size={40} height={32} />; break;
	}
	toast(
		<div className='flex middle' style={{zIndex: 100001}}>
			<div style={{margin: '0 8px'}}>{icon}</div>
			<p className='p0 m0'>{html}</p>   
		</div>, 
		{
			position: "top-right",
			// autoClose: 8000,
			closeButton: <Icon icon = "Close" size={20}/>,
			// hideProgressBar: true,
			progressStyle: {
				background: 'rgba(255,255,255, 0.6)',
			},
			draggable: true,
			style: {
				backgroundColor: background,
				color: 'white',
				zIndex: 1000,
				fontWeight: 'bold',
				borderRadius: '12px'
			}
		}
	);
}

export const proxy = config.REACT_APP_PROXY || 'http://127.0.0.1:5000/'
export const Now = () => Math.round(new Date().getTime() / 1000);

export const TF = (time: number, offset: number = 2) => {
	// let iOffset = Number(offset);
	let date= new Date(time);
	let y = date.getUTCFullYear();
	let m = date.getUTCMonth() + 1;
	let d = date.getUTCDate();
	let hh = date.getUTCHours();
	let mm = date.getUTCMinutes();
	let ss = date.getUTCSeconds();
	let dt = ("0" + m).slice(-2) + "-" + ("0" + d).slice(-2);
	let tt = ("0" + hh).slice(-2) + ":" + ("0" + mm).slice(-2) + ":" + ("0" + ss).slice(-2);
	return y + '-' + dt + ' ' + tt;
}

export const NF = (num: number, p: number = 2) => num.toLocaleString('en', { maximumFractionDigits: p });
export const validateEmail = (email: string): boolean => email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) !== null;
export const validateUsername = (username: string): boolean => username.match(/^[a-zA-Z ]{3,20}$/) !== null;
export const validateNumber = (number : string) : boolean =>   parseFloat(number) > 0;
export const validatePhone = (number : string) : boolean =>  /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g.test(number);
export const validatePassword = (number : string) : boolean =>  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$#!%*?&])[A-Za-z\d@$!%*?&#]{8,30}$/g.test(number);
export const validateUrl = (str : string) :boolean => {
	var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
	  '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
	  '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
	  '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
	  '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
	  '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
	return !!pattern.test(str);
  }


export const toLocaleSting = (time) => {
	let date= new Date(time);
	let y = date.getUTCFullYear();
	let m = date.getUTCMonth() + 1;
	let d = date.getUTCDate();
	let hh = date.getUTCHours();
	let mm = date.getUTCMinutes();
	let ss = date.getUTCSeconds();
	let dt = ("0" + m).slice(-2) + "-" + ("0" + d).slice(-2);
	let tt = ("0" + hh).slice(-2) + ":" + ("0" + mm).slice(-2) + ":" + ("0" + ss).slice(-2);
	return y + '-' + dt + 'T' + tt;

}

export const copyToClipboard = (text: string) => {
	var textField = document.createElement('textarea')
	textField.innerText = text
	document.body.appendChild(textField)
	textField.select()
	document.execCommand('copy')
	textField.remove()
	if (text.length > 40) {
		text = text.substring(0, 15) + "..." + text.substring(text.length - 5);
	}
	tips("info", "コピー済み: " + text);
};

export const toBigNum = (value, d) => {
	return ethers.utils.parseUnits(Number(value).toFixed(d), d);
}

export const fromBigNum = (value, d) => {
	return parseFloat(ethers.utils.formatUnits(value, d));
}
export const toBalance = (value) => {
	var f = parseFloat(ethers.utils.formatEther(value));
	if(f<0) { f= 0; }
	return NF(f, 4);
}


export const ellipsis = (address: string, start: number=6) => {
	if (!address || address === null) return ''
	const len = (start ) + 7;
	return address.length > len ? `${address?.slice(0, start)}...${address?.slice(-4)}` : address
}

const MONTH_NAMES = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

function getFormattedDate(date, prefomattedDate = false as any, hideYear = false) {
	const day = date.getDate();
	const month = MONTH_NAMES[date.getMonth()];
	const year = date.getFullYear();
	const hours = date.getHours();
	let minutes = date.getMinutes();
	if (minutes < 10) {
	  // Adding leading zero to minutes
	  minutes = `0${ minutes }`;
	}
	if (prefomattedDate) {
	  return `${ prefomattedDate } at ${ hours }:${ minutes }`;
	}
	if (hideYear) {
	  return `${ day }. ${ month } at ${ hours }:${ minutes }`;
	}
	// 10. January 2017. at 10:20
	return `${ day }. ${ month } ${ year }. at ${ hours }:${ minutes }`;
  }
  
export function timeAgo(dateParam) {
	if (!dateParam) {
	  return null;
	}
  
	const date = typeof dateParam === 'object' ? dateParam : new Date(dateParam);
	const DAY_IN_MS = 86400000; // 24 * 60 * 60 * 1000
	const today: any = new Date();
	const yesterday = new Date(today - DAY_IN_MS);
	const seconds = Math.round((today - date) / 1000);
	const minutes = Math.round(seconds / 60);
	const isToday = today.toDateString() === date.toDateString();
	const isYesterday = yesterday.toDateString() === date.toDateString();
	const isThisYear = today.getFullYear() === date.getFullYear();
  
	if (seconds < 5) {
	  return 'Now';
	} else if (seconds < 60) {
	  return `${ seconds } seconds ago`;
	} else if (seconds < 90) {
	  return 'About a minute ago';
	} else if (minutes < 60) {
	  return `${ minutes } minutes ago`;
	} else if (isToday) {
	  return getFormattedDate(date, 'Today'); // Today at 10:20
	} else if (isYesterday) {
	  return getFormattedDate(date, 'Yesterday'); // Yesterday at 10:20
	} else if (isThisYear) {
	  return getFormattedDate(date, false, true); // 10. January at 10:20
	}
  
	return getFormattedDate(date); // 10. January 2017. at 10:20
  }
  
  
function stripHexPrefix(value: string) {
	return value.slice(0, 2) === '0x' || value.slice(0, 2) === '0X' ? value.slice(2) : value
}
export const toChecksumAddress = (address: string) => {
	try {
		if (typeof address !== 'string') return ''
		if (!/^(0x)?[0-9a-f]{40}$/i.test(address)) throw new Error(`Given address "${address}" is not a valid Ethereum address.`)

		const stripAddress = stripHexPrefix(address).toLowerCase()
		const keccakHash = keccak('keccak256').update(stripAddress).digest('hex');
		let checksumAddress = '0x';
		for (let i = 0; i < stripAddress.length; i++) {
			checksumAddress +=
				parseInt(keccakHash[i], 16) >= 8
					? stripAddress[i]?.toUpperCase()
					: stripAddress[i]
		}
		return checksumAddress
	} catch (err) {
		// console.log(err)
		return null
	}
}
export const isByte32String = (address: string) => {
	try {
		if (typeof address !== 'string') return ''
		if (!/^(0x)?[0-9a-f]{64}$/i.test(address)) throw new Error(`Given address "${address}" is not a valid Ethereum address.`)
		return true
	} catch (err) {
		// console.log(err)
		return false
	}
}
