import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { useQuery } from "@apollo/client";
import Modal from '../../components/Modal'
import AdminLayout from '../../components/Layout/Layout'
import { ButtonPrimary } from '../../components/components'
import { GET_ADMINS } from '../../graphql/index';
import useStore from '../../useStore'
import { tips } from '../../util';

interface Admin {
	email: string
	root: boolean
	allow: boolean
	created: number
}

interface AdminListInterface {
	admins: Admin[]
	showDeleteModal: boolean
	selectedAdmin: string
}


const AdminList = () => {
	// @ts-ignore

	const { darkMode, token, logined, call, update } = useStore()
	const history = useHistory();

	const [status, setStatus] = React.useState<AdminListInterface>({
		admins: [],
		showDeleteModal: false,
		selectedAdmin: ''
	})
	const updateStatus = (params: Partial<AdminListInterface>) => setStatus({ ...status, ...params })

	const { data: userInfo, loading: infoLoading, error: infoError } = useQuery(GET_ADMINS, {
		variables: {
			token: token
		},
		fetchPolicy: 'network-only'
	});
	React.useEffect(() => {
		if (infoLoading || infoError) {
			return;
		}
		const data = userInfo.getAdmins;
		updateStatus({ admins: data })
	}, [userInfo, infoLoading, infoError]);


	const deleteAdmin = async (id: string) => {
		updateStatus({ showDeleteModal: true, selectedAdmin: id })
	}

	const handleDeleteAdmin = async () => {
		try {
			update({ loading: true })
			const result = await call(
				"/api/admin/delete-admin",
				{
					email: status.selectedAdmin
				},
				{ authorization: token }
			);
			if (result) {
				switch (result['message']) {
					case "success": {
						tips("success", "管理者を削除しました");
						const updatedList = [];
						status.admins.forEach(element => {
							if (element.email !== status.selectedAdmin) {
								updatedList.push(element)
							}
						});
						updateStatus({ showDeleteModal: false, admins: updatedList })
						break;
					};
					case "Not root admin": tips("warning", "このアクションにはルート権限が必要です"); break;
					case "internal error": tips("error", "サーバーエラー"); break;
				}
			}
			update({ loading: false })
		} catch (ex) {
			update({ loading: false })
			tips("error", "不明なエラー")
		}
	}

	React.useEffect(() => {
		if (!logined) history.push("/login")
	}, [logined])

	return (
		<AdminLayout>
			<StyledContainer className="container" >
				<h1 className='text-center' style={{ fontWeight: 'bolder' }}>Admin list</h1>
				<StyledListPanel darkMode={darkMode}>
					<div className="list-row header">
						<div>メールアドレス</div>
						<div>権限</div>
						<div>作成日</div>
						<div></div>
					</div>
					<div className="list-content">
						{
							status.admins.map((admin) => (
								<div className="list-row">
									<div>{admin?.email}</div>
									<div>{admin?.root ? "Root" : "Member"}</div>
									<div>{new Date(admin?.created * 1000).toLocaleDateString()}</div>
									<div>
										<ButtonPrimary style={{ width: '70px', padding: '4px 8px', margin: '4px', backgroundColor: '#59d398' }} onClick={() => { history.push("/admin/edit/" + admin?.email) }}>
											編集
										</ButtonPrimary>
										<ButtonPrimary style={{ width: '70px', padding: '4px 8px', margin: '4px' }} onClick={() => { deleteAdmin(admin.email) }}>
											削除
										</ButtonPrimary>
									</div>
								</div>
							))
						}
					</div>
				</StyledListPanel>
				<div className="flex center">
					<ButtonPrimary style={{ width: '200px' }} onClick={() => { history.push("/admin/add") }}>追加</ButtonPrimary>
				</div>
				<Modal onClose={() => { updateStatus({ showDeleteModal: false }) }} show={status.showDeleteModal} >
					<div className='mt2 mb1 p2'>
						<h1 className='title m0 p0 text-center'>Delete admin</h1>
						<div className="list-row header mt3">
							<div>メールアドレス</div>
							<div>権限</div>
							<div>作成日</div>
						</div>
						<div className="list-row">
							<div>{status.admins.find((admin) => admin.email === status.selectedAdmin)?.email}</div>
							<div>{status.admins.find((admin) => admin.email === status.selectedAdmin)?.root ? "Root" : ""}</div>
							<div>{new Date(status.admins.find((admin) => admin.email === status.selectedAdmin)?.created * 1000).toLocaleDateString()}</div>
						</div>
						<div className="flex center">
							<ButtonPrimary style={{ width: '250px', marginTop: '2rem' }} onClick={() => { handleDeleteAdmin() }}>
								削除する
							</ButtonPrimary>
						</div>
						<div className="flex center">
							<ButtonPrimary style={{ width: '250px', marginTop: '1rem', backgroundColor: "#24262f" }} onClick={() => { updateStatus({ showDeleteModal: false }) }}>
								キャンセル
							</ButtonPrimary>
						</div>
					</div>
				</Modal>
			</StyledContainer>
		</AdminLayout>
	)
}

export default AdminList;

const StyledContainer = styled.div`
	padding: 3rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	@media (max-width: 1024px) {
		padding: 1rem;
	}
	min-height: 100vh;
	
	.list-content {
		max-height: 50vh;
		min-height: 300px;
		overflow-y: auto;
	}
	.list-row{
		display: flex;
		flex-direction: row;
		align-items: center;
		align-content: center;
		padding: 8px;
		border-bottom: 1px solid ${({ theme }) => theme.strokeColor};
		@media (max-width: 768px) {
			display: block;
			text-align: center;
		}
		div:nth-child(1) {
			flex: 5;
		}
		div:nth-child(2) {
			flex: 2;
		}
		div:nth-child(3) {
			flex: 2;
		}
		div:nth-child(4) {
			flex: 3;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: flex-end;
			@media (max-width: 768px) {
				justify-content: center;
			}
		}
		&.header {
			font-size: 1.1rem;
			font-weight: bold;
			border-bottom: 1px solid ${({ theme }) => theme.strokeColor};
		}
	}
`

const StyledListPanel = styled.div<{ darkMode: boolean }>`
	border-radius: 2rem;
	background-color: ${({ theme }) => theme.boxColor};
	padding: 2rem 2rem;
	width: 100%;
	max-width: 960px;
	color: ${({ theme }) => theme.text};
	margin: 1rem auto;
	.title{
		color: ${({ theme, darkMode }) => darkMode ? theme.white : theme.black};
		text-align: center;
		font-size: 2rem;
		margin: 0;
	}
	a{
		text-align: center;
		color: #6b9beb;
	}
	@media (max-width: 768px) {
		width: 100%;
		padding: 2rem 1rem;
		margin: 0 auto 1rem;
		a{
			display: block;
		}
		.link{
			text-align: center;
		}
	}
	.logo{
		cursor: pointer;
		width: 240px;
		height: auto;
		@media (max-width: 768px) {
			margin: 0 8px;
			width: 150px;
		}
	}
`
