import { useMemo } from 'react'
import { ThemeProvider as StyledComponentsThemeProvider, css, DefaultTheme } from 'styled-components'
import { Colors } from './styled'
import useStore from '../useStore'

const MEDIA_WIDTHS = {
	upToExtraSmall: 500,
	upToSmall: 720,
	upToMedium: 960,
	upToLarge: 1280,
}

const mediaWidthTemplates: {
	[width in keyof typeof MEDIA_WIDTHS]: typeof css
} = Object.keys(MEDIA_WIDTHS).reduce((accumulator, size) => {
	; (accumulator as any)[size] = (a: any, b: any, c: any) => css`
		@media (max-width: ${(MEDIA_WIDTHS as any)[size]}px) {
			${css(a, b, c)}
		}
	`
	return accumulator
}, {}) as any

const white = '#FFFFFF'
const black = '#040404'
const grey = '#444444'
const link = '#6b9beb'
export function colors(darkMode: boolean): Colors {
	return {
		white,
		black,
		grey,

		link,
		accordion: darkMode ? '#2e2e2e' : '#ddd',
		bgColor: darkMode ? '#121212' : '#ffffff',
		bgColor2: darkMode ? '#141416' : '#ffffff',

		boxColor: darkMode ? '#24262f' : '#cccccc',
		modalBg: darkMode ? '#343437' : '#e7e7e7',
		modalOverlay: darkMode ? '#2d2633' : '#2d2633',

		text: darkMode ? '#fafafa' : '#262a34',
		textGrey: darkMode ? '#d1cfcf ' : '#717171',

		buttonPrimary: darkMode ? '#f56f63' : '#f56f63',
		buttonSecondary: darkMode ? '#3baf77' : '#3baf77',
		buttonWhite: darkMode ? '#dddddd' : '#262a34',

		buttonPrimaryHover: darkMode ? '#c7554b' : '#c7554b',
		buttonSecondaryHover: darkMode ? '#59d398' : '#59d398',
		buttonWhiteHover: darkMode ? '#ffffff' : '#262a34',

		strokeColor: darkMode ? '#707070' : '#707070',
		strokeWhite: darkMode ? '#e3e3e3' : '#707070',

		footerBar: darkMode ? '#141416' : '#ffffff',
		footerPanel: darkMode ? '#24262f' : '#6d6e72',

		disabled: darkMode ? '#a7a7a7' : '#a7a7a7',
	}
}

export function theme(darkMode: boolean): DefaultTheme {
	return {
		...colors(darkMode),

		grids: {
			sm: 8,
			md: 12,
			lg: 24,
		},

		//shadows
		shadow1: darkMode ? '#000' : '#2F80ED',

		modalOpacity: 0.74,

		// media queries
		mediaWidth: mediaWidthTemplates,

		// css snippets
		flexColumnNoWrap: css`
			display: flex;
			flex-flow: column nowrap;
		`,
		flexRowNoWrap: css`
			display: flex;
			flex-flow: row nowrap;
		`,
	}
}

export default function ThemeProvider({ children }: { children: any }) {
	const { darkMode } = useStore()
	const themeObject = useMemo(() => theme(darkMode), [darkMode])
	return (
		<StyledComponentsThemeProvider theme={themeObject}>
			{children}
		</StyledComponentsThemeProvider>
	)
}
