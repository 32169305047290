import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { useQuery } from "@apollo/client";
import styled, { ThemeContext } from 'styled-components'
import useStore from '../../useStore'
import Modal from '../../components/Modal'
import { Icon } from '../../components/Icon'
import Layout from '../../components/Layout/Layout'
import InputField from '../../components/InputField';
import { ButtonPrimary } from '../../components/components'
import { GET_USER_INFO } from '../../graphql/index';
import { tips, validateEmail, validateUrl } from '../../util';

import banner from '../../assets/img/banner.webp'
import avatar from '../../assets/img/avatar.png'

interface UserDetailInterface {
	address: string
	restoreUserModal: boolean
	stopUserModal: boolean
	acceptBadgeModal: boolean
	rejectBadgeModal: boolean
	deleteBadgeModal: boolean
	addBadgeModal: boolean
	userInfo: UserDataObject
	rejectreason: string
	reasonerror: string
	notfound: boolean
}

const badgeState = {
	verified: "取得済み",
	rejected: "拒否",
	pending: "申請中",
	"": "未申請"
}

const UserDetail = () => {
	// @ts-ignore
	
	const {darkMode, logined, token, call} = useStore();
	const history = useHistory();
	const theme = useContext(ThemeContext);
		
	const [status, setStatus] = React.useState<UserDetailInterface>({
		restoreUserModal: false,
		stopUserModal: false,
		acceptBadgeModal: false,
		rejectBadgeModal: false,
		deleteBadgeModal: false,
		addBadgeModal: false,
		address: window.location.pathname.split("/")?.[3]?.toString() || "",
		userInfo: null,
		rejectreason: '',
		reasonerror: '',
		notfound: false
	})
	const updateStatus = (params: Partial<UserDetailInterface>) => setStatus({ ...status, ...params })

    const {data: userInfo, loading: infoLoading, error: infoError } = useQuery(GET_USER_INFO, {
		variables: {
			address: status.address,
			badge: (status.userInfo?.verified?.status?.toString() || "not") + "," + "not,not,not,not",
			token: token,
			allow: status.userInfo?.allow
		},
		fetchPolicy: 'network-only'
	});
    React.useEffect(() => {
        if (infoLoading || infoError) {
            return;
        }
		if(userInfo.getUsersInfo && userInfo.getUsersInfo.length > 0) {
			const data = userInfo.getUsersInfo[0] as UserDataObject;
			updateStatus({userInfo: data})
		}
		else {
			updateStatus({notfound: true})
		}
    }, [userInfo, infoLoading, infoError]);

	const restoreUser = async () => {
		try {
			const result = await call("/api/admin/set-user-allow", {
				email: status.userInfo?.email,
				name: status.userInfo?.name,
				address: status.userInfo?.address,
				allow: true,
				reason: '',
			}, {authorization: token})
			if(result){
				switch(result['message']) {
					case "success": {
						tips("success", "Restored this user successfully"); 
						updateStatus({restoreUserModal: false, userInfo: {...status.userInfo, allow: {status: true, reason: ''}}})
						break;
					}
					case "internal error": tips("error",  "Server error"); break;
				}
			}
		} catch(ex) {
			return tips("error", "Unknown error")
		}
	}
	const stopUser = async () => {
		try {
			const result = await call("/api/admin/set-user-allow", {
				email: status.userInfo?.email,
				name: status.userInfo?.name,
				address: status.userInfo?.address,
				allow: false,
				reason: '',
			}, {authorization: token})
			if(result){
				switch(result['message']) {
					case "success": {
						tips("success", "Stoped this user successfully"); 
						updateStatus({stopUserModal: false, userInfo: {...status.userInfo, allow: {status: false, reason: ''}}})
						break;
					}
					case "internal error": tips("error",  "Server error"); break;
				}
			}
		} catch(ex) {
			return tips("error", "Unknown error")
		}
	}
	const addBadge = async () => {
		try {
			const result = await call("/api/admin/set-user-badge", {
				email: status.userInfo?.email,
				name: status.userInfo?.name,
				address: status.userInfo?.address,
				badge: "verified",
				reason: '',
			}, {authorization: token})
			if(result){
				switch(result['message']) {
					case "success": {
						tips("success", "Set badge successfully"); 
						updateStatus({addBadgeModal: false, userInfo: {...status.userInfo, verified: {status: "verified", reason: ''}}})
						break;
					}
					case "internal error": tips("error",  "Server error"); break;
				}
			}
		} catch(ex) {
			return tips("error", "Unknown error")
		}
	}
	const deleteBadge = async () => {
		try {
			const result = await call("/api/admin/set-user-badge", {
				email: status.userInfo?.email,
				name: status.userInfo?.name,
				address: status.userInfo?.address,
				badge: "",
				reason: '',
			}, {authorization: token})
			if(result){
				switch(result['message']) {
					case "success": {
						tips("success", "Deleted badge successfully"); 
						updateStatus({deleteBadgeModal: false, userInfo: {...status.userInfo, verified: {status: "", reason: ''}}})
						break;
					}
					case "internal error": tips("error",  "Server error"); break;
				}
			}
		} catch(ex) {
			return tips("error", "Unknown error")
		}
	}
	const rejectBadge = async () => {
		try {
			if(status.rejectreason.trim() === "") return updateStatus({reasonerror: "Please input reject reason"})
			const result = await call("/api/admin/set-user-badge", {
				email: status.userInfo?.email,
				name: status.userInfo?.name,
				address: status.userInfo?.address,
				badge: "rejected",
				reason: status.rejectreason,
			}, {authorization: token})
			if(result){
				switch(result['message']) {
					case "success": {
						tips("success", "Rejected badge successfully"); 
						updateStatus({rejectBadgeModal: false, userInfo: {...status.userInfo, verified: {status: "rejected", reason: status.rejectreason}}})
						break;
					}
					case "internal error": tips("error",  "Server error"); break;
				}
			}
		} catch(ex) {
			return tips("error", "Unknown error")
		}
	}
	const acceptBadge = async () => {
		try {
			const result = await call("/api/admin/set-user-badge", {
				email: status.userInfo?.email,
				name: status.userInfo?.name,
				address: status.userInfo?.address,
				badge: "verified",
				reason: '',
			}, {authorization: token})
			if(result){
				switch(result['message']) {
					case "success": {
						tips("success", "Accepted badge successfully"); 
						updateStatus({acceptBadgeModal: false, userInfo: {...status.userInfo, verified: {status: "verified", reason: ''}}})
						break;
					}
					case "internal error": tips("error",  "Server error"); break;
				}
			}
		} catch(ex) {
			return tips("error", "Unknown error")
		}
	}

	React.useEffect(() => {
		if(!logined) history.push("/login")
	}, [logined])


	return (
		<Layout>
			<StyledContainer className="container" >
				<div className="flex justify wrap ml-auto mr-auto w100"    style={{maxWidth: '960px'}}>
					<ButtonPrimary  style={{width: '100px', padding: '4px 8px', margin: '4px', backgroundColor: '#646464'}} onClick={() => {history.push("/user/list")}}>
						<div style={{width: '10px', height: '18px', marginRight: '1rem'}}>
							<svg viewBox="189.89 1093 9.149 17.86"><path d="M198.665 1110.486a1.276 1.276 0 0 0 0-1.804l-5.85-5.85a1.303 1.303 0 0 1 0-1.804l5.85-5.85a1.275 1.275 0 1 0-1.804-1.805l-5.85 5.85a3.827 3.827 0 0 0 0 5.412l5.85 5.851a1.276 1.276 0 0 0 1.804 0Z" fill="currentColor" fill-rule="evenodd"></path></svg>
						</div>
						戻る
					</ButtonPrimary>
					<h1 className='text-center ml-auto mr-auto' style={{fontWeight: 'bolder'}}>User detail</h1>
					<div style={{width: '100px'}} />
				</div>
				{
					status.notfound && <h1 className='text-center' style={{marginTop: '140px'}}>Could not found user data</h1>
				}
				{
					!status.notfound && 
					<StyledUserPanel darkMode={darkMode}>
						<img src= {status.userInfo?.banner_img || banner} className='banner'/>
						<div className="flex justify middle mt2">
							<div className="flex middle">
								<img src={status.userInfo?.avatar_img || avatar} className='avatar' />
								<div className='ml2'>
									<p className='m0'>{status.userInfo?.name}</p>
									<p className='small-text' style={{color: theme.textGrey}}>{status.userInfo?.address}</p>
								</div>
							</div>
							<div className='flex wrap'>
								<p className='ml3'>作成日</p>
								<p className='ml3'>{new Date(status.userInfo?.created * 1000).toLocaleDateString()}</p>
								<p className='ml3'>最終ログイン</p>
								<p className='ml3'>{new Date(status.userInfo?.lasttime * 1000).toLocaleDateString()}</p>
							</div>
						</div>
						<div className="flex center middle wrap">
							{ 
								status.userInfo?.twitter && validateUrl(status.userInfo?.twitter || "") && 
								<div className="flex middle ml1 mr1 mt1">
									<Icon icon="Twitter" margin={12} size={30}/>
									<a className='m0 p0' style={{textDecoration: 'none', color: theme.text}} href = {status.userInfo?.twitter}>{status.userInfo?.twitter}</a>
								</div>
							}
							{
								status.userInfo?.instagram && validateUrl(status.userInfo?.instagram || "") && 
								<div className="flex middle ml1 mr1 mt1">
									<Icon icon="Instagram" margin={12} size={30}/>
									<a className='m0 p0' style={{textDecoration: 'none', color: theme.text}} href = {status.userInfo?.instagram}>{status.userInfo?.instagram}</a>
								</div>
							}
							{
								status.userInfo?.link && validateUrl(status.userInfo?.link || "") && 
								<div className="flex middle ml1 mr1 mt1">
									<Icon icon="Website" margin={7} size={30} height={24}/>
									<a className='m0 p0' style={{textDecoration: 'none', color: theme.text}} href = {status.userInfo?.link}>{status.userInfo?.link}</a>
								</div>
							}
							{
								validateEmail(status.userInfo?.email || "") && <div className="flex middle ml2 mr2 mt2">
									<p className='m0 p0'>Email: {status.userInfo?.email}</p>
								</div>
							}
						</div>
						<div className="hr mt3"></div>
						<div className="flex center">
							<fieldset className="InputField mt3 mb3" style={{maxWidth: '600px', width: '100%'}}>
								<legend>bio</legend>
								<p>{status.userInfo?.bio}</p>
							</fieldset>
						</div>
						<div className="flex justify ml-auto mr-auto" style={{maxWidth: '600px'}}>
							<div className="flex">
								<p className='mr2'>バッジリクエストステータス</p>
								<p className='mr2'>{badgeState[status.userInfo?.verified?.status || ""]}</p>
							</div>
							<div className="flex">
								{
									(status.userInfo?.verified?.status === "" || status.userInfo?.verified?.status === "rejected") && <ButtonPrimary  style={{width: '100px', padding: '4px 8px', margin: '4px', backgroundColor: '#59d398'}} onClick={() => {updateStatus({addBadgeModal: true})}}>
										バッジ付与
									</ButtonPrimary>
								}
								{
									status.userInfo?.verified?.status === "pending" && 
									<ButtonPrimary  style={{width: '100px', padding: '4px 8px', margin: '4px', backgroundColor: '#59d398'}} onClick={() => {updateStatus({acceptBadgeModal: true})}}>
										承認
									</ButtonPrimary>
								}
								{
									status.userInfo?.verified?.status === "pending" && 
									<ButtonPrimary  style={{width: '100px', padding: '4px 8px', margin: '4px'}} onClick={() => {updateStatus({rejectBadgeModal: true})}}>
										拒否
									</ButtonPrimary>
								}
								{
									status.userInfo?.verified?.status === "verified" && 
									<ButtonPrimary  style={{width: '100px', padding: '4px 8px', margin: '4px'}} onClick={() => {updateStatus({deleteBadgeModal: true})}}>
									バッジ削除
									</ButtonPrimary>
								}
							</div>
						</div>
						{
							status.userInfo?.verified?.status === "rejected" && <div className="flex center mt1">
								<div style={{maxWidth: '600px', backgroundColor: '#646464', padding: '1rem', width: '100%'}}>
									<p className='m0'>{status.userInfo?.verified?.reason}</p>
								</div>
							</div>
						}
						<div className="flex center mt3">
							{
								status.userInfo?.allow?.status && <ButtonPrimary  style={{width: '250px'}} onClick={() => {updateStatus({stopUserModal: true})}}>
									停止する
								</ButtonPrimary>
							}
							{
								!status.userInfo?.allow?.status &&
								<ButtonPrimary  style={{width: '250px', backgroundColor: '#59d398'}} onClick={() => {updateStatus({restoreUserModal: true})}}>
									復元する
								</ButtonPrimary>
							}
						</div>
					</StyledUserPanel>
				}
			</StyledContainer>
			<Modal onClose = {() => {updateStatus({restoreUserModal : false})}} show={status.restoreUserModal}>
				<div className='mt2 mb1'>
					<h1 className='title m0 p0 text-center'>ユーザーの停止を解除</h1>
					<p className='ml-auto mr-auto text-center' style={{maxWidth: '500px'}}>ユーザーの停止を解除すると、NFTの取引や暗号資産の売買、 NFTの作成などを行えるようになります。</p>
					
					<div className="flex center">
						<ButtonPrimary style={{width: '250px', marginTop: '2rem'}} onClick={restoreUser}>解除する</ButtonPrimary>
					</div>
					<div className="flex center">
						<ButtonPrimary style={{width: '250px', marginTop: '1rem', backgroundColor: "#24262f"}} onClick={() => {updateStatus({restoreUserModal : false})}}>キャンセル</ButtonPrimary>
					</div>
				</div>
			</Modal>
			<Modal onClose = {() => {updateStatus({stopUserModal : false})}} show={status.stopUserModal} >
				<div className='mt2 mb1'>
					<h1 className='title m0 p0 text-center'>ユーザーを停止</h1>
					<p className='ml-auto mr-auto text-center' style={{maxWidth: '500px'}}>停止したユーザーはNFTの取引、暗号資産の売買、 NFTの作成などを行うことができなくなります</p>
					<div className="flex center">
						<ButtonPrimary style={{width: '250px', marginTop: '2rem'}} onClick={stopUser}>停止する</ButtonPrimary>
					</div>
					<div className="flex center">
						<ButtonPrimary style={{width: '250px', marginTop: '1rem', backgroundColor: "#24262f"}} onClick={() => {updateStatus({stopUserModal : false})}}>キャンセル</ButtonPrimary>
					</div>
				</div>
			</Modal>
			<Modal onClose = {() => {updateStatus({acceptBadgeModal : false})}} show={status.acceptBadgeModal} >
				<div className='mt2 mb1'>
					<h1 className='title m0 p0 text-center'>バッジ申請の承認</h1>
					<p className='ml-auto mr-auto text-center' style={{maxWidth: '500px'}}>このユーザーのバッジ申請を承認しますか？</p>
					<div className="flex center">
						<ButtonPrimary style={{width: '250px', marginTop: '2rem'}} onClick={acceptBadge}>承認する</ButtonPrimary>
					</div>
					<div className="flex center">
						<ButtonPrimary style={{width: '250px', marginTop: '1rem', backgroundColor: "#24262f"}} onClick={() => {updateStatus({acceptBadgeModal : false})}}>キャンセル</ButtonPrimary>
					</div>
				</div>
			</Modal>
			<Modal onClose = {() => {updateStatus({rejectBadgeModal : false})}} show={status.rejectBadgeModal}>
				<div className='mt2 mb1'>
					<h1 className='title m0 p0 text-center'>バッジ申請の拒否</h1>
					<InputField type="textarea" label={"拒否の理由"} value = {status.rejectreason} errorLabel={status.reasonerror} onChange = {(e) => {updateStatus({rejectreason: e.target.value})}}></InputField>
					<div className="flex center">
						<ButtonPrimary style={{width: '250px', marginTop: '2rem'}} onClick={rejectBadge}>拒否する</ButtonPrimary>
					</div>
					<div className="flex center">
						<ButtonPrimary style={{width: '250px', marginTop: '1rem', backgroundColor: "#24262f"}} onClick={() => {updateStatus({rejectBadgeModal : false})}}>キャンセル</ButtonPrimary>
					</div>
				</div>
			</Modal>
			<Modal onClose = {() => {updateStatus({deleteBadgeModal : false})}} show={status.deleteBadgeModal} >
				<div className='mt2 mb1'>
					<h1 className='title m0 p0 text-center'>バッジの削除</h1>
					<p className='ml-auto mr-auto text-center' style={{maxWidth: '500px'}}>このユーザーのバッジを削除しますか？</p>
					<div className="flex center">
						<ButtonPrimary style={{width: '250px', marginTop: '2rem'}} onClick={deleteBadge}>削除する</ButtonPrimary>
					</div>
					<div className="flex center">
						<ButtonPrimary style={{width: '250px', marginTop: '1rem', backgroundColor: "#24262f"}} onClick={() => {updateStatus({deleteBadgeModal : false})}}>キャンセル</ButtonPrimary>
					</div>
				</div>
			</Modal>
			<Modal onClose = {() => {updateStatus({addBadgeModal : false})}} show={status.addBadgeModal} >
				<div className='mt2 mb1'>
					<h1 className='title m0 p0 text-center'>バッジ付与</h1>
					<p className='ml-auto mr-auto text-center' style={{maxWidth: '500px'}}>このユーザーのバッジを付与しますか？</p>
					<div className="flex center">
						<ButtonPrimary style={{width: '250px', marginTop: '2rem'}} onClick={addBadge}>削除する</ButtonPrimary>
					</div>
					<div className="flex center">
						<ButtonPrimary style={{width: '250px', marginTop: '1rem', backgroundColor: "#24262f"}} onClick={() => {updateStatus({addBadgeModal : false})}}>キャンセル</ButtonPrimary>
					</div>
				</div>
			</Modal>
		</Layout>
	)
}

export default UserDetail;

const StyledContainer = styled.div`
	padding: 1rem 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	@media (max-width: 1024px) {
		padding: 1rem 0;
	}
	min-height: 100vh;
`

const StyledUserPanel = styled.div<{darkMode: boolean}>`
	border-radius: 2rem;
	background-color: ${({theme}) => theme.boxColor};
	padding: 2rem 2rem;
	width: 100%;
	max-width: 960px;
	color: ${({theme}) => theme.text};
	margin: 0 auto 2rem;
	.title{
		color: ${({theme, darkMode}) => darkMode ? theme.white:theme.black};
		text-align: center;
		font-size: 2rem;
		margin: 0;
	}
	a{
		text-align: center;
		color: #6b9beb;
	}
	p{
		word-break: break-all;
	}
	@media (max-width: 768px) {
		width: 100%;
		padding: 2rem 1rem;
		margin: 1rem auto 1rem;
		a{
			display: block;
		}
		.link{
			text-align: center;
		}
	}
	.banner {
		width:100%;
		height: 150px;
		border-radius: 8px;
		object-fit: cover;
	}
	.avatar {
		width: 65px;
		height: 65px;
		border-radius: 50%;
		object-fit: cover;
	}
`
