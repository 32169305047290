import React, { useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import { ButtonPrimary } from "./components";
import Icon from "./Icon";

interface SearchInterface {
	value?: string
	onValueChange?: Function
	onSearch?: Function
	resetValue?: Function
	onEnter?: Function
	props?: any
	placeholder?: string
}

export default function Search({ value, onValueChange, placeholder, resetValue, onSearch, onEnter, props }: SearchInterface) {
	// @ts-ignore

	const theme = useContext(ThemeContext);

	return (
		<SearchBar	{...props}>
			<div className="search-input">
				<input type="text" placeholder={placeholder || "Search"} style={{ fontSize: '1.1rem' }} onKeyDown={(e) => { if (e.key === "Enter") { onEnter && onEnter() } }} value={value || ""} onChange={(e) => { onValueChange && onValueChange(e.target.value) }} ></input>
				<div className="search-btn" onClick={() => { value === "" ? (onSearch && onSearch()) : resetValue && resetValue() }}>
					<Icon icon={value === "" ? "Search" : "Close"} />
				</div>
				<ButtonPrimary className="search-btn-right" style={{ borderRadius: '8px' }} onClick={() => { onSearch && onSearch() }}>検索</ButtonPrimary>
			</div>
		</SearchBar>
	)
}


const SearchBar = styled.div`
	width: 50%;
	border: 1px solid ${({ theme }) => (theme.strokeWhite)};
	background-color: transparent;
	border-radius: 8px;
	position: relative;
	padding: 8px 1rem;
	max-width: 800px;
	min-width: 290px;
	.search-btn{
		position: absolute;
		cursor: pointer;
		left: 1rem;
		top: 14px;	
		&:hover{
			color:	${({ theme }) => (theme.textGrey)};
			+.search-result-panel{
				display: block;
			}
		}
	}
	.search-btn-right{
		position: absolute;
		right: 4px;
		top: 0.25rem;
		width: 80px;
		padding: 8px 1rem;
	}
	input{
		border: none;
		padding: 4px 0 4px 2rem;
		width: calc(100% - 100px);
		background-color: transparent;
		user-select: none;
		color:	${({ theme }) => (theme.text)};
		&:hover, &:focus, &:active{
			border: none;
			outline: none;
			box-shadow: none;
		}
		@media (max-width: 768px) {
			width: 30%;
		}
	}
	
	@media (max-width: 768px) {
		width: 80%; 
	}
`
