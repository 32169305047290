import React, { useContext } from 'react'
import { Link, useHistory } from 'react-router-dom';
import styled, { ThemeContext } from 'styled-components'
import { Stack } from '@mui/material';

import useStore from '../../useStore';
import Icon from '../../components/Icon';
import logo from '../../assets/img/logo.png'
import logoblack from '../../assets/img/logoblack.png'

interface HeaderInterface {
  showWalletModal: boolean
  showAlertModal: boolean
  alertCount: number
  keyword: string
  balances: Balance[]
  totalUSD: string
  showDepositModal: boolean
  showWithdrawModal: boolean
  showTransferModal: boolean
  showBuyModal: boolean
  prices: Prices[]
  alerts: Alert[]
  paylableTokens: PaylableTokens[]
  transferTokenType: string
  withdrawTokenType: string
  transferTokenAmount: number
  withdrawTokenAmount: number
  transferTokenAddress: string
  withdrawTokenAddress: string
  transferTokenAmountError: string
  withdrawTokenAmountError: string
  transferTokenAddressError: string
  withdrawTokenAddressError: string
  withdrawError: string
  transferError: string
  buyError: string
}

const Header = () => {
  // @ts-ignore
  const theme = useContext(ThemeContext)
  const { darkMode, update } = useStore()
  const history = useHistory();

  const logOut = () => {
    update({ currentAccountMail: "", token: "", logined: false, lasttime: 0 });
    history.push("/login");
  }

  const [showMenu, setShowMenu] = React.useState(false)

  return (
    <>
      <StyledMobileHeader className='justify center' style={{ padding: '2rem 1rem 0' }}>
        <div></div>

        <Link to="/" style={{ textDecoration: 'none', alignItems: 'center', margin: '0' }} >
          <div className="flex middle">
            <div>
              <img src={darkMode ? logo : logoblack} className="logo" alt="logo" style={{ width: '150px' }} />
            </div>
            <h3 style={{ color: theme.text, textDecoration: 'none', padding: 0, margin: '0' }}>admin</h3>
          </div>
        </Link>

        <div style={{ color: 'white', cursor: 'pointer' }} onClick={() => { setShowMenu(true) }}>
          <Icon icon="Menu" size={20} />
        </div>
      </StyledMobileHeader>

      <StyledHeader darkMode={darkMode} showMenu={showMenu}>
        {window.innerWidth < 1024 && (
          <div style={{ position: 'absolute', right: '1rem', top: '1rem', cursor: 'pointer' }} onClick={() => { setShowMenu(false) }}>
            <Icon icon="Close" size={18} />
          </div>
        )}

        <div className="container">
          <HeaderContainer>
            <Stack flex={1}>
              <Link to="/" style={{ textDecoration: 'none' }} className='header-logo'>
                <div><img src={darkMode ? logo : logoblack} className="logo" alt="logo" /></div>
                <h3 style={{ color: theme.text, textDecoration: 'none', padding: 0, margin: '0' }}>admin</h3>
              </Link>

              <Link to="/dashboard" className='menu'> ダッシュボード</Link>
              <Link to="/admin/list" className='menu'> 管理者管理 </Link>
              <Link to="/wallet" className='menu'> ウォレット管理 </Link>
              <Link to="/user/list" className='menu'> ユーザー管理 </Link>
              <Link to="/nft/list" className='menu'>NFT管理  </Link>
              <Link to="/collection/list" className='menu'>コレクション管理 </Link>
              <Link to="/tokens" className='menu'>トークン管理 </Link>
              <Link to="/profile" className='menu'>管理者のメールアドレス</Link>
            </Stack>

            <LogOutButton onClick={() => { logOut() }}>
              ログアウト
            </LogOutButton>
          </HeaderContainer>
        </div>
      </StyledHeader>
    </>
  )
}

const StyledMobileHeader = styled.div`
	display: none;

	@media (max-width: 1024px) {
		display: flex;
	}
`

const StyledHeader = styled.div<{ darkMode: boolean, showMenu: boolean }>`
  left: 0; top: 0;
  position: fixed;

  width: 300px;
  max-width: 300px;
	min-height: 100vh;
	background-color:  #24262f;

	z-index: 1000;
	padding: 0.5rem 0;
	transition: 0.2s;
	overflow-y: auto;

  @media (max-width: 1024px) {
		width: 26%;
		min-width: 280px;
		display: ${({ showMenu }) => (showMenu ? 'block' : 'none')};
	}

	@media (max-width: 1024px) {
		width: 50%;
		min-width: 280px;
		display: ${({ showMenu }) => (showMenu ? 'block' : 'none')};
	}

	.header-logo {
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 3rem 1rem;
		@media (max-width: 1024px) {
			margin: 1rem;
		}
	}

	header{
		padding: 1rem 1.2rem;
		overflow-y: auto;
		height: 100vh;

		.logo{
			cursor: pointer;
			width: 180px;
			height: auto;	
			@media (max-width: 1280px) {
				width: 120px;
			}
		}

		.menu {
			display: block;
			text-decoration: none;
			cursor: pointer;
			padding: 1rem;
			color:  ${({ theme }) => (theme.text)};
			border-bottom: 1px solid   ${({ theme }) => (theme.strokeColor)};
			transition: 0.2s;
			&:hover {
				border-bottom: 1px solid   ${({ theme }) => (theme.text)};
				transform: translateY(-2px);
			}
		}
	}
`

const HeaderContainer = styled.header`
	gap: 20px;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`

const LogOutButton = styled.div`
	padding: 3px 0rem;
	cursor: pointer;
`

export default Header;