import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import styled, { ThemeContext } from 'styled-components'
import AdminLayout from '../../components/Layout/Layout'
import InputField from '../../components/InputField'
import useStore from '../../useStore'

import avatar from '../../assets/img/avatar.png'
import { ButtonPrimary } from 'components/components'
import { validatePassword, tips } from '../../util'

interface LoginInterface {
	password1: string
	password2: string
	errorpassword1: string
	errorpassword2: string
}

const AdminEdit = () => {
	// @ts-ignore

	const { darkMode, currentAccountMail, token, logined, call } = useStore()
	const history = useHistory();


	const [status, setStatus] = React.useState<LoginInterface>({
		password1: '',
		password2: '',
		errorpassword1: '',
		errorpassword2: '',
	})

	const updateStatus = (params: Partial<LoginInterface>) => setStatus({ ...status, ...params })


	React.useEffect(() => {
		if (!logined) history.push("/login")
	}, [logined])

	const changePassword = async () => {
		try {
			if (status.password1.trim() === "" || !validatePassword(status.password1)) return updateStatus({ errorpassword1: "上記のパスワードの形式でご入力ください", errorpassword2: "" });
			if (status.password1 !== status.password2) return updateStatus({ errorpassword2: "上記のパスワードの形式でご入力ください", errorpassword1: "" });

			const result = await call("/api/admin/change-password", {
				password: status.password1
			}, { authorization: token })
			if (result) {
				switch (result['message']) {
					case "success": {
						tips("success", "パスワードが正常に変更されました");
						updateStatus({ errorpassword1: "", errorpassword2: "", password1: "", password2: "" })
						break;
					}
				}
			}
		} catch (err) {
			console.log(err)
		}
	}

	return (
		<AdminLayout>
			<StyledContainer className="container">
				<StyledListPanel darkMode={darkMode}>
					<h3 className='title m0 p0'>パスワード変更</h3>
					<div className="flex middle center" >
						<h3 className='ml3' >{currentAccountMail}</h3>
					</div>
					<InputField label="パスワード" type="password" errorLabel={status.errorpassword1} value={status.password1} onChange={(e) => { updateStatus({ password1: e.target.value }) }} />
					<InputField label="パスワード再入力" type="password" errorLabel={status.errorpassword2} value={status.password2} onChange={(e) => { updateStatus({ password2: e.target.value }) }} />
					<div className="flex center mt3">
						<ButtonPrimary style={{ width: '200px' }} onClick={changePassword} >リセットする</ButtonPrimary>
					</div>
				</StyledListPanel>
			</StyledContainer>
		</AdminLayout>
	)
}

export default AdminEdit;



const StyledContainer = styled.div`
	padding: 1rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	@media (max-width: 1024px) {
		padding: 1rem;
	}
	min-height: 100vh;
`

const StyledListPanel = styled.div<{ darkMode: boolean }>`
	border-radius: 2rem;
	background-color: ${({ theme }) => theme.boxColor};
	padding: 2rem 5rem;
	width: 100%;
	max-width: 600px;
	color: ${({ theme }) => theme.text};
	margin: 3rem auto;
	.title{
		color: ${({ theme, darkMode }) => darkMode ? theme.white : theme.black};
		text-align: center;
		font-size: 2rem;
		margin: 0;
	}
	a{
		text-align: center;
		color: #6b9beb;
	}
	@media (max-width: 768px) {
		width: 100%;
		padding: 2rem 1rem;
		margin: 1rem auto 2rem;
		a{
			display: block;
		}
		.link{
			text-align: center;
		}
	}
`
