import { gql } from "@apollo/client";

const GET_PRICES = gql`
	query GetPrice($token: String) {
		getPrice(token: $token) {
			address
			name
			symbol
			decimals
			icon
			usd
			jpy
			eur
		}
	}
`;

const GET_ALERT = gql`
	query GetAlert($email: String) {
		getAlert(email: $email) {
			_id
			title
			from
			content
			created
			status
		}
	}
`;

const GET_ADMINS = gql`
	query GetAdmins($token: String){
		getAdmins(token: $token){
			email
			root
			allow
			created
		}
	}
`

const GET_FEE = gql`
	query GetFee {
		getFee {
			tradeFee
			exchangeFee
			nftpaymentFee
			onRampSideFee
		}
	}
`

const GET_TRADE_CHART = gql`
	query GetTradeChart($token: String) {
		getTradeChart(token: $token) {
			data {
				_id
				tradeVolumeJpy
			}
		}
	}
`

const GET_CRYPTO_CHART = gql`
	query GetCryptoChart($token: String) {
		getCryptoChart(token: $token) {
			data {
				_id
				tradeVolumeJpy
			}
		}
	}
`

const GET_ADMIN_WALLET = gql`
	query GetAdminWallet {
		getAdminWallet {
			addresses {
				exchange
				nft
				treasury
				marketplace
			}
			alerts {
				exchange {
				  address
				  balance
				}
				marketplace {
				  address
				  balance
				}
				nft {
				  address
				  balance
				}
				treasury {
				  address
				  balance
				}
			}
		}
	}
`

const GET_USER_INFO = gql`
	query GetUsersInfo($username: String, $address: String, $token: String, $badge: String) {
		getUsersInfo(username: $username, address: $address, token: $token, badge: $badge) {
			address
			name
			bio
			email
			phone
			twitter
			instagram
			link
			avatar_img
			banner_img
			created
			lasttime
			verified {
				status
				reason
			}
			allow {
				status
				reason
			}
			balances {
				address
				name
				symbol
				balance
				decimals
				icon
			}
		}
	}
`;

const GET_CATEGORY = gql`
	query GetCategory {
		getCategory {
			key
			category
		}
	}
`;
const GET_USER_BALANCE = gql`
	query GetUserBalance($address: String) {
		getUserBalance(address: $address) {
			address
			name
			symbol
			balance
			decimals
			icon
		}
	}
`;

const GET_COLLECTION_INFO = gql`
	query GetCollectionInfo($address: String, $badge: String, $owner: String, $name: String, $acceptedToken: String, $page: Float, $limit: Float, $sort: String, $category: String, $adminPage: String) {
		getCollectionInfo(address: $address, name: $name, badge: $badge, owner:$owner, acceptedToken: $acceptedToken, page: $page, limit: $limit, sort: $sort, category: $category, adminPage: $adminPage) {
			address
			owner
			isLazyMint
			fee
			error
			url
			category
			chainId
			hide
			metadata {
				name
				links {
				  name
				  link
				}
				image
				description
				coverImage
			}
			created
			verified {
				status
				reason
			}
		}
	}
`;

const GET_COLLECTION_CACHE_DATA = gql`
	query GetCollectionCacheData($address: String) {
		getCollectionCacheData(address: $address) {
			address
			items
			owners
			volume
			floor
			bestoffer
		}
	}
`;

const GET_FAVORITED = gql`
	query GetFavoritedNFT($collection: String, $nft: String, $userAddress: String) {
		getFavoritedNFT(collection: $collection, nft: $nft, userAddress: $userAddress) {
			collectionid
			nftid
			userEmail
			userAddress
		}
	}
`;

const GET_PAYLABLE_TOKEN = gql`
	query GetPaylableToken {
		getPaylableToken {
			name
			symbol
			decimals
			icon
			address
			usd
			jpy
			eur
			isNative
			internalLimit
		}
	}
`;

const GET_NFTS = gql`
	query GetNFTs($nftcollection: String, $owner: String, $creator: String, $query: String, $tokenid: String, $page: Float, $limit: Float, $sort: String, $price1: Float, $price2: Float, $searchsymbol: String, $salestatus: String, $symbols: String, $adminPage:String) {
		getNFTs(nftcollection: $nftcollection, query: $query, owner: $owner, creator: $creator, tokenid: $tokenid, page: $page, limit: $limit, sort: $sort, price1: $price1, price2: $price2, searchsymbol: $searchsymbol, salestatus: $salestatus,  symbols: $symbols, adminPage: $adminPage) {
			_id
			nftCollection
			tokenid
			reacts
			views
			favs
			name
			description
			saleType
			image
			creator
			coverImage
			externalSite
			attributes {
				key
				value
			}
			updatedBlockNum
			error
			owner
			acceptedToken
			price
			expiredTime
			hide
			pick
			bidders {
				bidder
				price
				expiredTime
			}
		}
	}
`;

const GET_FAVORITE_NFT = gql`
	query GetFavoriteNFT($owner: String) {
		getFavoriteNFT(owner: $owner) {
			_id
			nftCollection
			tokenid
			reacts
			views
			favs
			name
			description
			saleType
			image
			coverImage
			externalSite
			attributes {
				key
				value
			}
			updatedBlockNum
			error
			owner
			acceptedToken
			price
			expiredTime
			bidders {
				bidder
				price
				acceptedToken
				quantity
				startTime
				expiredTime
				created
			}
		}
	}
`;


const GET_NFT_ACTIVITY = gql`
	query GetNFTActivity($nftcollection: String, $nftid: String, $type: String) {
		getNFTActivity(nftcollection: $nftcollection, nftid: $nftid, type: $type) {
			id
			nftCollection
			created
			params {
				type
				value
			}
			type
		}
	}
`

const GET_TRADE_INFO = gql`
	query GetAdminTradeBase($token: String) {
		getAdminTradeBase(token: $token) {
			today
			yesterday
			week
			week2
			total
			total2
		}
	}
`;

const GET_TRADE_INFO_SYMBOL = gql`
	query GetTradeSymbol($token: String) {
		getTradeSymbol(token: $token) {
			nft {
				tradeVolumeJpy
				tradeVolumeSymbol
				feeSymbol
				feeJpy
				symbol
			}
			crypto {
				tradeVolumeJpy
				tradeVolumeSymbol
				feeSymbol
				feeJpy
				symbol
			}
		}
	}
`;
const GET_EXCHANGE_INFO_SYMBOL = gql`
	query GetExchangeSymbol($token: String) {
		getExchangeSymbol(token: $token) {
			tradeVolumeJpy
			tradeVolumeSymbol
			feeSymbol
			feeJpy
			_id
		}
	}
`;


export { GET_PRICES, GET_ADMINS, GET_TRADE_INFO_SYMBOL, GET_EXCHANGE_INFO_SYMBOL, GET_CRYPTO_CHART, GET_TRADE_CHART, GET_TRADE_INFO, GET_FEE, GET_ADMIN_WALLET, GET_NFT_ACTIVITY, GET_NFTS, GET_FAVORITE_NFT, GET_CATEGORY, GET_USER_INFO, GET_USER_BALANCE, GET_FAVORITED, GET_ALERT, GET_COLLECTION_INFO, GET_PAYLABLE_TOKEN, GET_COLLECTION_CACHE_DATA };
