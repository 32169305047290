import { useContext } from "react";
import { Checkbox } from "@mui/material";
import { ThemeContext } from "styled-components";

interface SwitchInterface{
    checked?: boolean
    changeEvent: Function
    color?: string
}

export default function CustomCheckbox({checked, color, changeEvent}: SwitchInterface) {
  // @ts-ignore
	const theme = useContext(ThemeContext)
  
  return (
    <Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 32 }, color: theme.text,  '&.Mui-checked': {
      color: color || theme.text,
    } }} onChange={(e) => {changeEvent(e.target.checked)}}  checked={checked}/>
  );
}
