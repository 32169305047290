import React from 'react'
import styled from 'styled-components'
import { Typography } from '@mui/material';
import { useHistory } from 'react-router-dom'
import { useQuery } from "@apollo/client";
import { formatUnits } from 'ethers/lib/utils';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import useStore from '../../useStore'
import Config from '../../config/config.json'
import Icon from '../../components/Icon';
import Modal from '../../components/Modal';
import Dropdown from '../../components/Dropdown';
import InputField from '../../components/InputField';
import AdminLayout from '../../components/Layout/Layout'
import { ButtonPrimary } from '../../components/components';
import { ZeroAddress, getTokenBalance } from '../../blockchain/utils';
import { copyToClipboard, ellipsis, tips } from '../../util';
import { GET_PAYLABLE_TOKEN, GET_ADMIN_WALLET } from '../../graphql/index';
import { getTokenContract_m, multicallHelper, multicallProvider } from '../../blockchain/contract';

interface tokenBalance extends PaylableTokens {
  balance: string
}

interface WalletDashboard {
  paylableTokens: PaylableTokens[]
  showUserLimitModal: boolean
  showSettingAlertModal: boolean
  limitToken: string
  limitAmount: number
  errorLimitAmount: string
  nftWallet: string
  exchangeWallet: string
  treasuryWallet: string
  marketplaceContract: string
  nftWalletAlert: { address: string, balance: number }[]
  exchangeWalletAlert: { address: string, balance: number }[]
  treasuryWalletAlert: { address: string, balance: number }[]
  marketplaceContractAlert: { address: string, balance: number }[]
  nftBalance: tokenBalance[]
  exchangeBalance: tokenBalance[]
  treasuryBalance: tokenBalance[]
  marketplaceBalance: tokenBalance[]
  checking: boolean
  amountETHNFT: number
  amountETHTreasury: number
  amountUSDTTreasury: number
  amountETHExchange: number
  amountUSDTExchange: number
  amountETHMarketplace: number
  amountUSDTMarketplace: number
  errorAmountETHNFT: string
  errorAmountETHTreasury: string
  errorAmountUSDTTreasury: string
  errorAmountETHExchange: string
  errorAmountUSDTExchange: string
  errorAmountETHMarketplace: string
  errorAmountUSDTMarketplace: string
}

const WalletDashboard = () => {
  // @ts-ignore
  const history = useHistory();
  const { logined, token, call } = useStore()

  const [status, setStatus] = React.useState<WalletDashboard>({
    paylableTokens: [],
    showUserLimitModal: false,
    showSettingAlertModal: false,
    limitToken: 'MATIC',
    limitAmount: 0,
    errorLimitAmount: '',
    nftWallet: '',
    exchangeWallet: '',
    treasuryWallet: '',
    marketplaceContract: '',
    nftWalletAlert: [],
    exchangeWalletAlert: [],
    treasuryWalletAlert: [],
    marketplaceContractAlert: [],
    nftBalance: [],
    exchangeBalance: [],
    treasuryBalance: [],
    marketplaceBalance: [],
    checking: true,
    amountETHNFT: 0,
    amountETHTreasury: 0,
    amountUSDTTreasury: 0,
    amountETHExchange: 0,
    amountUSDTExchange: 0,
    amountETHMarketplace: 0,
    amountUSDTMarketplace: 0,
    errorAmountETHNFT: '',
    errorAmountETHTreasury: '',
    errorAmountUSDTTreasury: '',
    errorAmountETHExchange: '',
    errorAmountUSDTExchange: '',
    errorAmountETHMarketplace: '',
    errorAmountUSDTMarketplace: ''
  })
  const updateStatus = (params: Partial<WalletDashboard>) => setStatus({ ...status, ...params })

  const { data: paylableTokensInfo, loading: paylableTokensLoading, error: paylableTokensError } = useQuery(GET_PAYLABLE_TOKEN, {
    fetchPolicy: 'network-only'
  })

  React.useEffect(() => {
    if (paylableTokensLoading || paylableTokensError) return;
    const data = paylableTokensInfo?.getPaylableToken as PaylableTokens[];
    if (!data) return;
    updateStatus({ paylableTokens: data, limitAmount: data.find(token => token.symbol === status.limitToken)?.internalLimit })
  }, [paylableTokensInfo, paylableTokensLoading, paylableTokensError])

  const { data: marketAdminInfo, loading: marketAdminLoading, error: marketAdminError } = useQuery(GET_ADMIN_WALLET, {
    fetchPolicy: 'network-only'
  })

  React.useEffect(() => {
    if (marketAdminLoading || marketAdminError) return;
    const data = marketAdminInfo?.getAdminWallet as any;
    const usdtAddress = status.paylableTokens.find(token => token.symbol === "USDT")?.address

    if (!!data && usdtAddress) {
      console.log(data?.alerts['nft'])

      updateStatus({
        nftWallet: data?.addresses['nft'],
        exchangeWallet: data?.addresses['exchange'],
        treasuryWallet: data?.addresses['treasury'],
        marketplaceContract: data?.addresses['marketplace'],
        nftWalletAlert: data?.alerts['nft'],
        exchangeWalletAlert: data?.alerts['exchange'],
        treasuryWalletAlert: data?.alerts['treasury'],
        marketplaceContractAlert: data?.alerts['marketplace'],
        amountETHNFT: data?.alerts['nft']?.find(t => t.address === ZeroAddress)?.balance,
        amountETHTreasury: data?.alerts['treasury']?.find(t => t.address === ZeroAddress)?.balance,
        amountUSDTTreasury: data?.alerts['treasury']?.find(t => t.address === usdtAddress)?.balance,
        amountETHExchange: data?.alerts['exchange']?.find(t => t.address === ZeroAddress)?.balance,
        amountUSDTExchange: data?.alerts['exchange']?.find(t => t.address === usdtAddress)?.balance,
        amountETHMarketplace: data?.alerts['marketplace']?.find(t => t.address === ZeroAddress)?.balance,
        amountUSDTMarketplace: data?.alerts['marketplace']?.find(t => t.address === usdtAddress)?.balance,
      })
    }
  }, [marketAdminInfo, marketAdminLoading, marketAdminError, status.paylableTokens])


  React.useEffect(() => {
    if (!logined) history.push("/login")
  }, [logined])

  React.useEffect(() => {
    if (status.paylableTokens && status.nftWallet) {
      checkBalance()
    }
  }, [status.nftWallet, status.exchangeWallet, status.treasuryWallet, status.paylableTokens])

  const checkBalance = async () => {
    try {
      const nftBalances = [], exchangeBalances = [], treasuryBalances = [], marketplaceBalances = [];

      updateStatus({ checking: true })
      for (let i = 0; i < status.paylableTokens.length; i++) {
        const token = status.paylableTokens[i];
        const address = token.address;
        let nftBalance, exchangeBalance, treasuryBalance, marketplaceBalance;

        if (address === ZeroAddress) {
          let multiCalls = [];
          multiCalls.push(multicallProvider.getEthBalance(status.nftWallet));
          multiCalls.push(multicallProvider.getEthBalance(status.exchangeWallet));
          multiCalls.push(multicallProvider.getEthBalance(status.treasuryWallet));
          multiCalls.push(multicallProvider.getEthBalance(status.marketplaceContract));
          const balances = await multicallHelper(multiCalls);
          // console.log(balances)

          nftBalance = formatUnits(balances[0])
          exchangeBalance = formatUnits(balances[1])
          treasuryBalance = formatUnits(balances[2])
          marketplaceBalance = formatUnits(balances[3])
        } else {
          let multiCalls = [];
          const contract = getTokenContract_m(address)
          multiCalls.push(contract.balanceOf(status.nftWallet));
          multiCalls.push(contract.balanceOf(status.exchangeWallet));
          multiCalls.push(contract.balanceOf(status.treasuryWallet));
          multiCalls.push(contract.balanceOf(status.marketplaceContract));
          const balances = await multicallHelper(multiCalls);

          // console.log(balances, token.decimals)
          nftBalance = formatUnits(balances[0], token.decimals)
          exchangeBalance = formatUnits(balances[1], token.decimals)
          treasuryBalance = formatUnits(balances[2], token.decimals)
          marketplaceBalance = formatUnits(balances[3], token.decimals)
        }

        nftBalances.push({ ...token, balance: Number(Number(nftBalance).toFixed(4)) })
        exchangeBalances.push({ ...token, balance: Number(Number(exchangeBalance).toFixed(4)) })
        treasuryBalances.push({ ...token, balance: Number(Number(treasuryBalance).toFixed(4)) })
        marketplaceBalances.push({ ...token, balance: Number(Number(marketplaceBalance).toFixed(4)) })
      };

      // console.log(nftBalances, exchangeBalances, treasuryBalances, marketplaceBalances)
      updateStatus({
        nftBalance: nftBalances,
        exchangeBalance: exchangeBalances,
        treasuryBalance: treasuryBalances,
        marketplaceBalance: marketplaceBalances,
        checking: false
      })
    } catch (err) {
      console.log(err.message)
      updateStatus({ checking: false })
    }
  }

  const setUserWalletLimit = async () => {
    if (Number(status.limitAmount) < 0.001) return updateStatus({ errorLimitAmount: "無効な値" });
    const tokenAddress = status.paylableTokens.find(token => token.symbol === status.limitToken)?.address || ZeroAddress;
    const result = await call("/api/admin/set-userwallet-limit", {
      address: tokenAddress,
      limit: Number(status.limitAmount)
    }, { authorization: token })
    if (result) {
      switch (result['message']) {
        case "success": {
          tips("success", "正常に変更されました");
          const tokens = [];
          status.paylableTokens.forEach(token => {
            if (token.address !== tokenAddress) tokens.push(token)
            else tokens.push({
              ...token,
              internalLimit: status.limitAmount
            })
          })
          updateStatus({ errorLimitAmount: "", paylableTokens: tokens })
          break;
        }
      }
    }
  }

  const setAdminWalletAlert = async () => {
    if (Number(status.amountETHNFT) < 0.001) return updateStatus({ errorAmountETHNFT: "無効な値" });
    if (Number(status.amountETHTreasury) < 0.001) return updateStatus({ errorAmountETHTreasury: "無効な値" });
    if (Number(status.amountUSDTTreasury) < 0.001) return updateStatus({ errorAmountUSDTTreasury: "無効な値" });
    if (Number(status.amountETHExchange) < 0.001) return updateStatus({ errorAmountETHExchange: "無効な値" });
    if (Number(status.amountUSDTExchange) < 0.001) return updateStatus({ errorAmountUSDTExchange: "無効な値" });
    if (Number(status.amountETHMarketplace) < 0.001) return updateStatus({ errorAmountETHMarketplace: "無効な値" });
    if (Number(status.amountUSDTMarketplace) < 0.001) return updateStatus({ errorAmountUSDTMarketplace: "無効な値" });

    const usdtAddress = status.paylableTokens.find(token => token.symbol === "USDT")?.address
    const setting = [
      {
        type: "nft",
        alert: [
          {
            address: ZeroAddress,
            balance: status.amountETHNFT
          }
        ]
      }, {
        type: "treasury",
        alert: [
          {
            address: ZeroAddress,
            balance: status.amountETHTreasury
          },
          {
            address: usdtAddress,
            balance: status.amountUSDTTreasury
          }
        ]
      }, {
        type: "exchange",
        alert: [
          {
            address: ZeroAddress,
            balance: status.amountETHExchange
          },
          {
            address: usdtAddress,
            balance: status.amountUSDTExchange
          }
        ]
      }, {
        type: "marketplace",
        alert: [
          {
            address: ZeroAddress,
            balance: status.amountETHMarketplace
          },
          {
            address: usdtAddress,
            balance: status.amountUSDTMarketplace
          }
        ]
      },
    ];

    const result = await call("/api/admin/set-adminwallet-alert", {
      alerts: setting
    }, { authorization: token })

    if (result) {
      switch (result['message']) {
        case "success": {
          tips("success", "正常に変更されました");
          break;
        }
      }
    }
  }

  return (
    <AdminLayout>
      <StyledContainer className="container" >
        <h2>Wallets dashboard</h2>

        <div className="row">
          <div className="col-xl-4 col-lg-6 col-sm-12">
            <StyledPanel>
              <div className="flex justify middle">
                <h3 className='m1'>NFT manage wallet</h3>

                <div className="flex middle">
                  <Typography variant='body2' sx={{ opacity: 0.75 }} mr={0.3}>
                    {ellipsis(status.nftWallet, 5)}
                  </Typography>

                  <div className='cursor' onClick={() => { copyToClipboard(status.nftWallet) }}>
                    <Icon icon="Copy" size={20} />
                  </div>

                  <div className='cursor' onClick={() => { window.open(Config.explorer + status.nftWallet, "_blank") }}>
                    <Icon icon="Link" size={18} marginLeft={10} />
                  </div>
                </div>
              </div>

              <div className="hr"></div>

              {status.checking && (
                <SkeletonTheme baseColor={"#888"} highlightColor={"#444"}>
                  <p><Skeleton height={15} width={"100%"} count={3} /></p>
                </SkeletonTheme>
              )}

              {status.nftBalance.map((token, index) => (
                <div className="token" key={"token" + index}>
                  <div className="icon">
                    <img src={token.icon} alt="icon" style={{ width: '25px', height: '25px', borderRadius: '50%' }} />
                  </div>

                  <div className="name">
                    {token.symbol}
                  </div>

                  <div className="balance">
                    {Number(token.balance).toFixed(3)}
                  </div>

                  {token.symbol === 'MATIC' ? (
                    Number(token.balance) < status.amountETHNFT ? (
                      <div className='error'></div>
                    ) : (
                      Number(token.balance) < Number(status.amountETHNFT) * 1.5 ? (
                        <div className='warning'></div>
                      ) : (
                        <div></div>
                      )
                    )
                  ) : (
                    <div></div>
                  )}
                </div>
              ))}
            </StyledPanel>
          </div>

          <div className="col-xl-4 col-lg-6 col-sm-12">
            <StyledPanel>
              <div className="flex justify middle">
                <h3 className='m1'>Exchange wallet</h3>

                <div className="flex middle">
                  <Typography variant='body2' sx={{ opacity: 0.75 }} mr={0.3}>
                    {ellipsis(status.exchangeWallet, 5)}
                  </Typography>

                  <div className='cursor' onClick={() => { copyToClipboard(status.exchangeWallet) }}>
                    <Icon icon="Copy" size={20} />
                  </div>

                  <div className='cursor' onClick={() => { window.open(Config.explorer + status.exchangeWallet, "_blank") }}>
                    <Icon icon="Link" size={18} marginLeft={10} />
                  </div>
                </div>
              </div>

              <div className="hr"></div>

              {status.checking && (
                <SkeletonTheme baseColor={"#888"} highlightColor={"#444"}>
                  <p><Skeleton height={15} width={"100%"} count={3} /></p>
                </SkeletonTheme>
              )}

              {status.exchangeBalance.map((token, index) => (
                <div className="token" key={"token" + index}>
                  <div className="icon">
                    <img src={token.icon} alt="icon" style={{ width: '25px', height: '25px', borderRadius: '50%' }} />
                  </div>

                  <div className="name">
                    {token.symbol}
                  </div>

                  <div className="balance">
                    {Number(token.balance).toFixed(3)}
                  </div>

                  {token.symbol === 'MATIC' ? (
                    Number(token.balance) < status.amountETHExchange ?
                      <div className='error'></div> :
                      Number(token.balance) < Number(status.amountETHExchange) * 1.5 ? <div className='warning'></div>
                        :
                        <div></div>
                  ) : (
                    <div></div>
                  )}

                  {token.symbol === 'USDT' ? (
                    Number(token.balance) < status.amountUSDTExchange ?
                      <div className='error'></div> :
                      Number(token.balance) < Number(status.amountUSDTExchange) * 1.5 ? <div className='warning'></div>
                        :
                        <div></div>
                  ) : (
                    <div></div>
                  )}
                </div>
              ))}
            </StyledPanel>
          </div>

          <div className="col-xl-4 col-lg-6 col-sm-12">
            <StyledPanel>
              <div className="flex justify middle">
                <h3 className='m1'>Treasury wallet</h3>

                <div className="flex middle">
                  <Typography variant='body2' sx={{ opacity: 0.75 }} mr={0.3}>
                    {ellipsis(status.treasuryWallet, 5)}
                  </Typography>

                  <div className='cursor' onClick={() => { copyToClipboard(status.treasuryWallet) }}>
                    <Icon icon="Copy" size={20} />
                  </div>

                  <div className='cursor' onClick={() => { window.open(Config.explorer + status.treasuryWallet, "_blank") }}>
                    <Icon icon="Link" size={18} marginLeft={10} />
                  </div>
                </div>
              </div>

              <div className="hr"></div>

              {status.checking && (
                <SkeletonTheme baseColor={"#888"} highlightColor={"#444"}>
                  <p><Skeleton height={15} width={"100%"} count={3} /></p>
                </SkeletonTheme>
              )}

              {status.treasuryBalance.map((token, index) => (
                <div className="token" key={"token" + index}>
                  <div className="icon">
                    <img src={token.icon} alt="icon" style={{ width: '25px', height: '25px', borderRadius: '50%' }} />
                  </div>
                  <div className="name">
                    {token.symbol}
                  </div>
                  <div className="balance">
                    {Number(token.balance).toFixed(3)}
                  </div>

                  {token.symbol === 'MATIC' ? (
                    Number(token.balance) < status.amountETHTreasury ?
                      <div className='error'></div> :
                      Number(token.balance) < Number(status.amountETHTreasury) * 1.5 ? <div className='warning'></div>
                        :
                        <div></div>
                  ) : (
                    <div></div>
                  )}

                  {token.symbol === 'USDT' ? (
                    Number(token.balance) < status.amountUSDTTreasury ?
                      <div className='error'></div> :
                      Number(token.balance) < Number(status.amountUSDTTreasury) * 1.5 ? <div className='warning'></div>
                        :
                        <div></div>
                  ) : (
                    <div></div>
                  )}
                </div>
              ))}
            </StyledPanel>
          </div>

          <div className="col-xl-4 col-lg-6 col-sm-12">
            <StyledPanel>
              <div className="flex justify middle">
                <h3 className='m1'>Marketplace contract</h3>

                <div className="flex middle">
                  <Typography variant='body2' sx={{ opacity: 0.75 }} mr={0.3}>
                    {ellipsis(status.marketplaceContract, 5)}
                  </Typography>

                  <div className='cursor' onClick={() => { copyToClipboard(status.marketplaceContract) }}>
                    <Icon icon="Copy" size={20} />
                  </div>

                  <div className='cursor' onClick={() => { window.open(Config.explorer + status.marketplaceContract, "_blank") }}>
                    <Icon icon="Link" size={18} marginLeft={10} />
                  </div>
                </div>
              </div>

              <div className="hr"></div>

              {status.checking && (
                <SkeletonTheme baseColor={"#888"} highlightColor={"#444"}>
                  <p><Skeleton height={15} width={"100%"} count={3} /></p>
                </SkeletonTheme>
              )}

              {status.marketplaceBalance.map((token, index) => (
                <div className="token" key={"token" + index}>
                  <div className="icon">
                    <img src={token.icon} alt="icon" style={{ width: '25px', height: '25px', borderRadius: '50%' }} />
                  </div>

                  <div className="name">
                    {token.symbol}
                  </div>

                  <div className="balance">
                    {Number(token.balance).toFixed(3)}
                  </div>

                  {token.symbol === 'MATIC' ? (
                    Number(token.balance) < status.amountETHMarketplace ?
                      <div className='error'></div> :
                      Number(token.balance) < Number(status.amountETHMarketplace) * 1.5 ? <div className='warning'></div>
                        :
                        <div></div>
                  ) : (
                    <div></div>
                  )}

                  {token.symbol === 'USDT' ? (
                    Number(token.balance) < status.amountUSDTMarketplace ?
                      <div className='error'></div> :
                      Number(token.balance) < Number(status.amountUSDTMarketplace) * 1.5 ? <div className='warning'></div>
                        :
                        <div></div>
                  ) : (
                    <div></div>
                  )}
                </div>
              ))}
            </StyledPanel>
          </div>
        </div>

        <div className="flex wrap mt3">
          <ButtonPrimary style={{ minWidth: '250px', margin: '8px 8px 8px 0' }} onClick={() => { updateStatus({ showUserLimitModal: true }) }}>バッチ送信金額設定</ButtonPrimary>
          <ButtonPrimary style={{ backgroundColor: '#59d398', minWidth: '200px', margin: '8px 0' }} onClick={() => { updateStatus({ showSettingAlertModal: true }) }}>通知金額設定</ButtonPrimary>
        </div>
      </StyledContainer>

      <Modal onClose={() => { updateStatus({ showUserLimitModal: false }) }} show={status.showUserLimitModal} >
        <h2 className='text-center'>Batch transmission amount setting</h2>
        <p className='text-center'>
          ユーザーウォレットが所持する残高が、設定した最低金額を <br />
          上回ると自動的にアドミンウォレットに資金を送金します
        </p>

        <div style={{ maxWidth: '450px', margin: ' 1rem auto' }}>
          <InputField
            type="number"
            label={"最低金額"}
            errorLabel={status.errorLimitAmount}
            value={status.limitAmount}
            onChange={(e) => { updateStatus({ limitAmount: e.target.value }) }}
            min={0.0001}
            required
            child={
              <div style={{ width: '80px', position: 'absolute', right: '1.5rem', top: '0' }}>
                <Dropdown
                  selectedKey={status.limitToken}
                  hideImg
                  values={
                    status.paylableTokens
                  }
                  onChange={(key) => {
                    console.log(status.paylableTokens)
                    updateStatus({
                      limitToken: key,
                      limitAmount: status.paylableTokens.find(token => { return token.symbol === key })?.internalLimit
                    })
                  }}
                  props={{ style: { height: '100%' } }}
                />
              </div>
            }
          />
        </div>
        <div className="flex center mt5">
          <ButtonPrimary style={{ minWidth: '250px' }} onClick={setUserWalletLimit}>設定</ButtonPrimary>
        </div>
      </Modal>

      <Modal onClose={() => { updateStatus({ showSettingAlertModal: false }) }} show={status.showSettingAlertModal} >
        <h2 className='text-center'>Notification balance setting</h2>
        <p className='text-center'>
          設定したしきい値を下回った場合に <br />
          アドミンに通知を送信します
        </p>

        <div style={{
          maxWidth: '500px',
          margin: ' 1rem auto',
          padding: '1rem',
          maxHeight: '60vh',
          overflowY: 'auto',
          overflowX: "hidden",
        }}>
          <div className="justify">
            <h3 className='m0'>NFT manage wallet</h3>
            <div className="flex middle">
              <div className='cursor' onClick={() => { copyToClipboard(status.nftWallet) }}>
                <Icon icon="Copy" size={20} />
              </div>
              <div className='cursor' onClick={() => { window.open(Config.explorer + status.nftWallet, "_blank") }}>
                <Icon icon="Link" size={17} marginLeft={15} />
              </div>
            </div>
          </div>

          <InputField type="number"
            label="しきい値(MATIC) "
            errorLabel={status.errorAmountETHNFT}
            value={status.amountETHNFT}
            onChange={(e) => { updateStatus({ amountETHNFT: e.target.value }) }}
            min={0.0001}
            child={
              <div style={{ position: 'absolute', minWidth: '80px', textAlign: 'center', right: '8px', top: '0', backgroundColor: "#5e6270", padding: '8px 1rem', borderRadius: '8px' }}>
                MATIC
              </div>
            }
          />

          <div className="justify mt3">
            <h3 className='m0'>Treasury wallet</h3>
            <div className="flex middle">
              <div className='cursor' onClick={() => { copyToClipboard(status.treasuryWallet) }}>
                <Icon icon="Copy" size={20} />
              </div>
              <div className='cursor' onClick={() => { window.open(Config.explorer + status.treasuryWallet, "_blank") }}>
                <Icon icon="Link" size={17} marginLeft={15} />
              </div>
            </div>
          </div>

          <InputField type="number"
            label="しきい値(USDT)"
            errorLabel={status.errorAmountUSDTTreasury}
            value={status.amountUSDTTreasury}
            onChange={(e) => { updateStatus({ amountUSDTTreasury: e.target.value }) }}
            min={0.0001}
            child={
              <div style={{ position: 'absolute', minWidth: '80px', textAlign: 'center', right: '8px', top: '0', backgroundColor: "#5e6270", padding: '8px 1rem', borderRadius: '8px' }}>
                USDT
              </div>
            }
          />

          <InputField
            type="number"
            label="しきい値(MATIC)"
            errorLabel={status.errorAmountETHTreasury}
            value={status.amountETHTreasury}
            onChange={(e) => { updateStatus({ amountETHTreasury: e.target.value }) }}
            min={0.0001}
            child={
              <div style={{ position: 'absolute', minWidth: '80px', textAlign: 'center', right: '8px', top: '0', backgroundColor: "#5e6270", padding: '8px 1rem', borderRadius: '8px' }}>
                MATIC
              </div>
            }
          />

          <div className="justify mt3">
            <h3 className='m0'>Exchange wallet</h3>
            <div className="flex middle">
              <div className='cursor' onClick={() => { copyToClipboard(status.exchangeWallet) }}>
                <Icon icon="Copy" size={20} />
              </div>
              <div className='cursor' onClick={() => { window.open(Config.explorer + status.exchangeWallet, "_blank") }}>
                <Icon icon="Link" size={17} marginLeft={15} />
              </div>
            </div>
          </div>

          <InputField type="number"
            label="しきい値(USDT)"
            errorLabel={status.errorAmountUSDTExchange}
            value={status.amountUSDTExchange}
            onChange={(e) => { updateStatus({ amountUSDTExchange: e.target.value }) }}
            min={0.0001}
            child={
              <div style={{ position: 'absolute', minWidth: '80px', textAlign: 'center', right: '8px', top: '0', backgroundColor: "#5e6270", padding: '8px 1rem', borderRadius: '8px' }}>
                USDT
              </div>
            }
          />
          <InputField type="number"
            label="しきい値(MATIC)"
            errorLabel={status.errorAmountETHExchange}
            value={status.amountETHExchange}
            onChange={(e) => { updateStatus({ amountETHExchange: e.target.value }) }}
            min={0.0001}
            child={
              <div style={{ position: 'absolute', minWidth: '80px', textAlign: 'center', right: '8px', top: '0', backgroundColor: "#5e6270", padding: '8px 1rem', borderRadius: '8px' }}>
                MATIC
              </div>
            }
          />

          <div className="justify mt3">
            <h3 className='m0'>Marketplace contract</h3>
            <div className="flex middle">
              <div className='cursor' onClick={() => { copyToClipboard(status.marketplaceContract) }}>
                <Icon icon="Copy" size={20} />
              </div>
              <div className='cursor' onClick={() => { window.open(Config.explorer + status.marketplaceContract, "_blank") }}>
                <Icon icon="Link" size={17} marginLeft={15} />
              </div>
            </div>
          </div>

          <InputField type="number"
            label="しきい値(USDT)"
            errorLabel={status.errorAmountUSDTMarketplace}
            value={status.amountUSDTMarketplace}
            onChange={(e) => { updateStatus({ amountUSDTMarketplace: e.target.value }) }}
            min={0.0001}
            child={
              <div style={{ position: 'absolute', minWidth: '80px', textAlign: 'center', right: '8px', top: '0', backgroundColor: "#5e6270", padding: '8px 1rem', borderRadius: '8px' }}>
                USDT
              </div>
            }
          />

          <InputField type="number"
            label="しきい値(MATIC)"
            errorLabel={status.errorAmountETHMarketplace}
            value={status.amountETHMarketplace}
            onChange={(e) => { updateStatus({ amountETHMarketplace: e.target.value }) }}
            min={0.0001}
            child={
              <div style={{ position: 'absolute', minWidth: '80px', textAlign: 'center', right: '8px', top: '0', backgroundColor: "#5e6270", padding: '8px 1rem', borderRadius: '8px' }}>
                MATIC
              </div>
            }
          />
        </div>

        <div className="flex center">
          <ButtonPrimary style={{ minWidth: '250px' }} onClick={setAdminWalletAlert}>設定</ButtonPrimary>
        </div>
      </Modal>
    </AdminLayout>
  )
}

const StyledContainer = styled.div`
	padding: 3rem;
	display: flex;
	flex-direction: column;

	@media (max-width: 1024px) {
		padding: 1rem 0;
	}

	min-height: 100vh;

  .row {
    .col-xl-4, .col-lg-6, .col-sm-12 {
      padding: 10px;
    }
  }
`

const StyledPanel = styled.div`
  height: 100%;
	border-radius: 1rem;
	background-color:  ${({ theme }) => theme.boxColor};
	padding: 1rem 1.5rem;
	margin: 0px auto;

	.green{
		color: #34c38f;
	}

	.red {
		color: #f46a6a;
	}

	.small{
		font-size: 0.9rem;
	}

	.grey {
		color: #74788d;
	}

	@media (max-width: 768px) {
		padding: 1rem;
	}

	.token{
		display: flex;
		align-items: center;
		padding: 1rem 0;
		.icon {
			flex: 2;
		}
		.name {
			flex: 3;
		}
		.balance {
			flex: 7;
		}
		border-bottom: 1px solid #888;
		.error{
			width: 10px;
			height: 10px;
			border-radius: 50%;
			background-color: #ff0303;
			display: block;
			-webkit-animation: error-animation 1s infinite alternate; /* Safari, Chrome and Opera > 12.1 */
			-moz-animation: error-animation 1s infinite alternate; /* Firefox < 16 */
			-ms-animation: error-animation 1s infinite alternate; /* Internet Explorer */
			-o-animation: error-animation 1s infinite alternate; /* Opera < 12.1 */
				animation: error-animation 1s infinite alternate;
		}

		@keyframes error-animation {
			from { 
				outline: 1px solid transparent;
			}
			to   { 
				outline: 6px solid rgb(255 122 122 / 20%)
			}
		}

		.warning{
			width: 10px;
			height: 10px;
			border-radius: 50%;
			background-color: #f5da39;
			display: block;
			-webkit-animation: warning-animation 1s infinite alternate; /* Safari, Chrome and Opera > 12.1 */
			-moz-animation: warning-animation 1s infinite alternate; /* Firefox < 16 */
			-ms-animation: warning-animation 1s infinite alternate; /* Internet Explorer */
			-o-animation: warning-animation 1s infinite alternate; /* Opera < 12.1 */
				animation: warning-animation 1s infinite alternate;
		}

		@keyframes warning-animation {
			from { 
				outline: 1px solid transparent;
			}
			to   { 
				outline: 6px solid rgb(255 235 122 / 20%)
			}
		}
	}
`

export default WalletDashboard;